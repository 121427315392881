using Bridge.Html5;
using StaticSiteToolbox.Models;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace StaticSiteToolbox.Controls
{
    public class Footer
    {
        public string BackgroundColor { get; set; }
        public string NavTextColor { get; set; }
        public string HoverTextColor { get; set; }
        public string LogoSrcDesktop { get; set; }
        public string LogoSrcMobile { get; set; }
        public int LogoWidthDesktop { get; set; }
        public int LogoHeightDesktop { get; set; }
        public int LogoWidthMobile { get; set; }
        public int LogoHeightMobile { get; set; }
        public int LogoPadding { get; set; }
        public List<string> NavItems { get; set; }
        public string CurrentSelection { get; set; }
        public bool MaskContentView { get; set; }
        public string Font { get; set; }
        public int FontSize { get; set; }
        public string TextContent { get; set; }
        public string HomePage { get; set; }

        public HTMLDivElement Content { get; set; }

        public void Draw()
        {
            if (App.UseDesktop)
            {
                Content = Draw_Desktop();
            }
            else
            {
                Content = Draw_Mobile();
            }
        }


        private HTMLDivElement Draw_Desktop()
        {
            //create logo image
            var logo = new HTMLImageElement
            {
                Src = LogoSrcDesktop,
                Width = LogoWidthDesktop,
                Height = LogoHeightDesktop,
                Style =
                {
                    PaddingLeft = LogoPadding.ToLocaleString() + "px",
                    Display = Display.Block,
                    MinWidth = LogoWidthDesktop.ToString() + "px",
                    Top = "0px",
                    Cursor = "pointer",
                },
            };

            logo.OnClick = (ev) =>
            {
                Navigation.ChangeContentView(HomePage);
            };


            //create list to contain navigation buttons
            var nav_list = new HTMLUListElement
            {
                Style =
                {
                    Display = Display.Table,
                    AlignItems = AlignItems.Stretch,
                    JustifyContent = JustifyContent.SpaceBetween,
                    AlignContent = AlignContent.FlexEnd,
                    VerticalAlign = VerticalAlign.TextTop,
                    //Position = Position.Absolute,
                    //Right = "0px",
                    //Top = "0px"
                },
            };

            //make nav buttons
            foreach (string item in NavItems)
            {
                string line = item.Replace(" ", "");

                //create navigation button
                var nav_button = new HTMLButtonElement
                {
                    Id = line,
                    InnerHTML = item,
                    Style =
                    {
                        //Display = Display.InlineFlex,
                        Padding = "20px",
                        Color = NavTextColor,
                        BackgroundColor = "rgba(0,0,0,0)", //transparent
                        Border = "0px", //no border
                        FontSize = FontSize + "px",
                        Flex = "0 0 auto",
                        FontFamily = Font,
                        Cursor = "pointer",
                    },

                };

                //set text color of current selection
                if (nav_button.Id == Navigation.CurrentPageName)
                {
                    nav_button.Style.Color = HoverTextColor;
                }

                //add on hover event to change text color
                nav_button.OnMouseOver = (ev) =>
                {
                    nav_button.Style.Color = HoverTextColor;
                };
                nav_button.OnMouseLeave = (ev) =>
                {
                    if (nav_button.Id != Navigation.CurrentPageName)
                    {
                        nav_button.Style.Color = NavTextColor;
                    }

                };

                //add on click text color and current selection change
                nav_button.OnClick = (ev) =>
                {
                    nav_button.Style.Color = HoverTextColor;

                    foreach (HTMLButtonElement button in nav_list.Children)
                    {
                        if (button.Id == Navigation.CurrentPageName)
                        {
                            button.Style.Color = NavTextColor;
                        }
                    }

                    //change the content view to the new page clicked
                    Navigation.ChangeContentView(nav_button.Id);
                };

                nav_list.AppendChild(nav_button);

            }

            //create wrapper container so can position elements
            var nav_wrapper = new HTMLDivElement
            {
                Style =
                {
                    Display = Display.InlineFlex,
                    //AlignItems = AlignItems.Center,
                    JustifyContent = JustifyContent.Center,

                    BackgroundColor = BackgroundColor, //assign background color
                    //Overflow = Overflow.Hidden, //not scrollable
                    //Position = Position.Absolute, //fixed position
                    Bottom = "0", //always stays on top of page
                    //Left = "0",
                    Width = "100%"
                },
            };

            //add logo and nav list to wrapper
            nav_wrapper.AppendChild(logo);
            nav_wrapper.AppendChild(new HTMLDivElement
            {
                InnerHTML = TextContent,//App.Company + " <br><br>All Rights Reserved.",
                Style =
                {
                    Padding = "20px",
                    Color = HTMLColor.Gray,
                    FontFamily = App.site_params.general.FontMain,
                    FontSize = (FontSize-4).ToString() + "px",
                    TextAlign = TextAlign.Left
                }
            });
            nav_wrapper.AppendChild(nav_list);

            return nav_wrapper;
        }

        private HTMLDivElement Draw_Mobile()
        {
            //create logo image
            var logo = new HTMLImageElement
            {
                Src = LogoSrcDesktop,
                Width = LogoWidthDesktop,
                Height = LogoHeightDesktop,
                Style =
                {
                    PaddingLeft = LogoPadding.ToLocaleString() + "px",
                    Display = Display.Block,
                    MinWidth = LogoWidthDesktop.ToString() + "px",
                    //Top = "0px"
                    TextAlign = TextAlign.Center,
                    AlignSelf = AlignItems.Center,
                    Cursor = "pointer",
                },
            };

            logo.OnClick = (ev) =>
            {
                Navigation.ChangeContentView(HomePage);
            };

            //create list to contain navigation buttons
            var nav_list = new HTMLUListElement
            {
                Style =
                {
                    Display = Display.Table,
                    AlignItems = AlignItems.Stretch,
                    JustifyContent = JustifyContent.SpaceBetween,
                    AlignContent = AlignContent.FlexEnd,
                    VerticalAlign = VerticalAlign.TextTop,
                    //Position = Position.Absolute,
                    //Right = "0px",
                    //Top = "0px"
                },
            };

            //make nav buttons
            foreach (string item in NavItems)
            {
                string line = item.Replace(" ", "");

                //create navigation button
                var nav_button = new HTMLButtonElement
                {
                    Id = line,
                    InnerHTML = item,
                    Style =
                    {
                        //Display = Display.InlineFlex,
                        Padding = "20px",
                        Color = NavTextColor,
                        BackgroundColor = "rgba(0,0,0,0)", //transparent
                        Border = "0px", //no border
                        FontSize = FontSize + "px",
                        Flex = "0 0 auto",
                        FontFamily = Font,
                        Cursor = "pointer",
                    },

                };

                //set text color of current selection
                if (nav_button.Id == Navigation.CurrentPageName)
                {
                    nav_button.Style.Color = HoverTextColor;
                }

                //add on hover event to change text color
                nav_button.OnMouseOver = (ev) =>
                {
                    nav_button.Style.Color = HoverTextColor;
                };
                nav_button.OnMouseLeave = (ev) =>
                {
                    if (nav_button.Id != Navigation.CurrentPageName)
                    {
                        nav_button.Style.Color = NavTextColor;
                    }

                };

                //add on click text color and current selection change
                nav_button.OnClick = (ev) =>
                {
                    nav_button.Style.Color = HoverTextColor;

                    foreach (HTMLButtonElement button in nav_list.Children)
                    {
                        if (button.Id == Navigation.CurrentPageName)
                        {
                            button.Style.Color = NavTextColor;
                        }
                    }

                    //change the content view to the new page clicked
                    Navigation.ChangeContentView(nav_button.Id);
                };

                nav_list.AppendChild(nav_button);

            }

            //create wrapper container so can position elements
            var nav_wrapper = new HTMLDivElement
            {
                Style =
                {
                    Display = Display.InlineFlex,
                    //AlignItems = AlignItems.Center,
                    JustifyContent = JustifyContent.Center,

                    BackgroundColor = BackgroundColor, //assign background color
                    //Overflow = Overflow.Hidden, //not scrollable
                    //Position = Position.Absolute, //fixed position
                    //Bottom = "0", //always stays on top of page
                    //Left = "0",
                    Width = "100%"
                },
            };

            //add logo and nav list to wrapper
            nav_wrapper.AppendChild(logo);
            nav_wrapper.AppendChild(new HTMLDivElement
            {
                InnerHTML = TextContent,//App.Company + " <br><br>All Rights Reserved.",
                Style =
                {
                    Padding = "20px",
                    Color = HTMLColor.Gray,
                    FontFamily = Font,
                    FontSize = (FontSize).ToString() + "px",
                    TextAlign = TextAlign.Center,
                    AlignSelf = AlignItems.Center
                }
            });
            nav_wrapper.AppendChild(nav_list);

            return nav_wrapper;
        }

    }
}
