using Bridge.Html5;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace StaticSiteToolbox.Controls
{

    public class Page
    {
        public string Id { get; set; }

        public ScrollPositioner scrollPositioner { get; set; }
        public HTMLDivElement Content { get; set; }

        public void Draw()
        {
            var container = new HTMLDivElement
            {
                //InnerHTML = "<br><br>Error Page?<br><br>",
                Style =
                {
                    Overflow = Overflow.Hidden,
                    BackgroundColor = App.site_params.general.ColorFontSecondary,
                    Width = "100%",
                    Top = "0px"
                },

            };

            Content = container;

            //DrawContent();

            Content.OnScroll = (ev) =>
            {
                scrollPositioner.ShowSelection();
            };

        }

        public void ResizeContent()
        {
            if (App.site_params.general.UseNavigationBar)
            {
                if (App.OnMobile)
                {
                    Content.Style.Top = (App.site_params.navbar.NavHeight).ToString() + "px";//(App.site_params.navbar.NavHeight * 2).ToString() + "px";
                    Content.Style.Height = (Window.InnerHeight - (App.site_params.navbar.NavHeight)).ToString() + "px";//(Window.InnerHeight - (App.site_params.navbar.NavHeight * 2)).ToString() + "px";
                    Content.Style.MinHeight = (Window.InnerHeight - (App.site_params.navbar.NavHeight)).ToString() + "px";//(Window.InnerHeight - (App.site_params.navbar.NavHeight * 2)).ToString() + "px";
                    //Console.WriteLine("rrr");
                }
                else
                {
                    Content.Style.Top = (App.site_params.navbar.NavHeight).ToString() + "px";
                    Content.Style.Height = (Window.InnerHeight - App.site_params.navbar.NavHeight).ToString() + "px";
                    Content.Style.MinHeight = (Window.InnerHeight - App.site_params.navbar.NavHeight).ToString() + "px";
                }

            }
            else
            {
                Content.Style.Top = (App.site_params.navbar.NavHeight).ToString() + "px";
                Content.Style.Height = (Window.InnerHeight).ToString() + "px";
            }
            try
            {
                scrollPositioner.RePosition();
            }
            catch { }
        }

    }
}
