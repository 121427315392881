using Bridge.Html5;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace StaticSiteToolbox.Controls
{
    public class SimpleCard : Card
    {
        public string Header { get; set; }
        public string Content { get; set; }
        public int HeaderFontSize { get; set; }
        public int ContentFontSize { get; set; }
        public string FontColor { get; set; }
        public string Font { get; set; }
        public string BackgroundColor { get; set; }

        new public void Draw()
        {
            var header = new HTMLDivElement
            {
                InnerHTML = Header,
                Style =
                {
                    FontSize = HeaderFontSize.ToString() + "px",
                    Color = FontColor,
                    TextAlign = TextAlign.Left,
                    Padding = "10px",
                    FontFamily = Font,
                }
            };

            var content = new HTMLDivElement
            {
                InnerHTML = Content,
                Style =
                {
                    FontSize = ContentFontSize.ToString() + "px",
                    Color = FontColor,
                    TextAlign = TextAlign.Left,
                    Padding = "10px",
                    FontFamily = Font,
                }
            };

            container = new HTMLDivElement
            {
                Style =
                {
                    Display = Display.Table,
                    BackgroundColor = BackgroundColor
                }
            };

            container.AppendChild(header);
            container.AppendChild(content);

            Indent();
        }
    }
}
