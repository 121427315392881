using Bridge;
using Newtonsoft.Json;
using System;
using StaticSiteToolbox.Models;
using Bridge.Html5;
using StaticSiteToolbox.Views;
using StaticSiteToolbox.ViewModels;
using System.Collections.Generic;

namespace StaticSiteToolbox
{
    public class App
    {
        

        //TODO:
        //collect information when the site is loaded and include it in the user collection 
        //such as time website is first visited
        //and each time a new page is loaded, include in the list (like a history log?)
        //could also record the scrollviewer each time a new card is highlighted
        //why all this? to track the generic usage of the website
        //but only collect all this if they become a customer?
        //will have the ww2 airplane issue


        //sample string to reference for choosing desktop mode override
        public static string desktop_mode_indicator = "DesktopMode";

        //site parameter global object
        public static SiteParams site_params { get; set; }

        //string of website that is json content
        public static string website_jsonstr { get; set; }

        //create main view
        public static MainView mainView = new MainView();

        //use dynamic desktop model
        public static bool UseDesktop = false; //true if displaying desktop mode
        public static bool OnMobile { get; set; } //true if rendering on a mobile device

        private static int last_width = 0;
        private static int last_height = 0;

        public static GeolocationPosition my_position;

        //so can go back and forth in app
        public static Action HistoryListener()
        {
            Action historyListener = Navigation.Navigate;
            return historyListener;
        }

        public delegate void PosDel(GeolocationPosition position);

        public static void PPos(GeolocationPosition position)
        {
            App.my_position = position;
            //Console.WriteLine(position.Coords.Latitude);
            //Console.WriteLine(position.Coords.Longitude);
        }

        //initializing main
        public static void Main()
        {
            //App.CollectUserData();

            //loads the website_jsonstr into the public static var
            Bridge.Script.Write("this.website_jsonstr=website_jsonstr");

            //load the site params from this website json str
            try
            {
                site_params = JsonConvert.DeserializeObject<SiteParams>(website_jsonstr, new JsonSerializerSettings { NullValueHandling = NullValueHandling.Ignore, TypeNameHandling = TypeNameHandling.Objects });
            }
            catch (Exception ex)
            {
                Console.WriteLine(ex.StackTrace);
                Console.WriteLine(ex.Message);
            }

            //add custom navigation override
            Window.AddEventListener(EventType.PopState, (Action)HistoryListener());

            //determine view mode
            DetermineMode();

            //set mainview's content page determined by the url hash
            Navigation.ChangeContentView(Window.Location.Hash);

            //set the window resizing event to redraw the app
            Window.OnResize = (ev) =>
            {
                try
                {
                    bool doDraw = false;
                    if ((Window.InnerWidth < last_width) && (Window.InnerWidth < site_params.general.thin_width_change) && (last_width > site_params.general.thin_width_change))
                    {
                        doDraw = true;
                    }
                    if ((Window.InnerWidth > last_width) && (Window.InnerWidth >= site_params.general.thin_width_change) && (last_width < site_params.general.thin_width_change))
                    {
                        doDraw = true;
                    }

                    if (doDraw)
                    {
                        DetermineMode();
                        App.Draw();
                    }

                    last_width = Window.InnerWidth;

                    if ((Window.InnerHeight - last_height) != 0)
                    {
                        mainView.contentViewer.ResizeVertical();
                    }
                    last_height = Window.InnerHeight;
                }
                catch (Exception e)
                {
                    //Console.WriteLine(e.StackTrace);
                    //Console.WriteLine(e.Message);
                    Console.WriteLine("Failed to Resize");
                }

            };

            //visual debugs
            Document.BgColor = "rgba(255,255,0,1)"; //bright yellow -> so can see raw document background
            Document.Body.Style.Overflow = Overflow.Hidden;

            //Window.Status = "this is test status";
            //Window.Name = "im window name";
            //Window.History.
            //Console.WriteLine(Window.Location.Host);
            //Console.WriteLine(Window.Location.HostName);
            //Console.WriteLine(Window.Location.Origin);
            //Console.WriteLine(Window.Location.Password);
            //Console.WriteLine(Window.Location.Username);
            //Console.WriteLine(Window.Navigator.UserAgent);
            //Console.WriteLine(Window.Navigator.AppName);
            //Console.WriteLine(Window.Navigator.AppVersion);
            //Console.WriteLine(Window.Navigator.Geolocation);
            //Console.WriteLine(Window.Navigator.Platform);
            //Console.WriteLine(Window.Navigator.Product);
            //Console.WriteLine(Window.Navigator.Language);
            //Console.WriteLine(Window.Name);

        }

        public static void DetermineMode()
        {
            //device: desktop
            //size: desktop
            if (Browser.IsDesktop && (Window.InnerWidth > site_params.general.thin_width_change))
            {
                App.OnMobile = false;
                App.UseDesktop = true;
            }
            //device: desktop
            //size: mobile
            else if (Browser.IsDesktop && (Window.InnerWidth <= site_params.general.thin_width_change))
            {
                App.OnMobile = false;
                App.UseDesktop = false;
            }
            //device: mobile
            //size: mobile
            else if (!Browser.IsDesktop && !App.UseDesktop)
            {
                App.OnMobile = true;
                App.UseDesktop = false;
            }
            //device: mobile
            //size: desktop
            else if (!Browser.IsDesktop && App.UseDesktop)
            {
                App.OnMobile = true;
                App.UseDesktop = true;
            }
        }

        public static void Draw()
        {
            //TODO: add in loading mask here
            //since static site, the pages load VERY fast
            //which may cause double clicks and strange user behaviour
            //so here a slight loading delay mask is used to tell user
            //their action occured and will be processed
            int pause_ms = site_params.general.PageLoadDelayMS;// 75;

            //var loading_mask = new HTMLDivElement
            //{
            //    InnerHTML = "LOADING MASK DEBUG",
            //    Style =
            //    {
            //        Position = Position.Absolute,
            //        Width = "200px",
            //        Height = "200px",
            //        BackgroundColor = "yellow"
            //    }
            //};
            //App.ClearView();
            //Document.Body.AppendChild(loading_mask);
            //tried this but the element wasnt rendered

            System.Threading.Thread.Sleep(pause_ms);

            App.ClearView();
            mainView.Draw();
            Document.Body.AppendChild(mainView.Content);

            //here code that must be executed AFTER DOM creation is run
            try
            {
                //Bridge.Script.Write("var elements = stripe.elements();");
                //Bridge.Script.Write("var card = elements.create('card');");
                Bridge.Script.Write("card.mount('#card-element');");

                //below still has errors and doesnt show?
                //https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_alert_role
            }
            catch (Exception ex)
            {
                //Console.WriteLine(ex.StackTrace);
                //Console.WriteLine(ex.Message);
            }


            //if (App.OnMobile)
            //{
            //    Document.Body.Style.Height = (Window.InnerHeight + Window.OuterHeight).ToString() + "px";
            //}

            //Console.WriteLine("capture these...");
            //CollectUserData();

        }

        private static void ClearView()
        {
            try { Document.Body.RemoveChild(mainView.Content); } catch { }
            mainView = new MainView();
        }

        public static List<Tuple<string,string>> CollectUserData(bool collect_geolocation)
        {

            List<Tuple<string, string>> user_data = new List<Tuple<string, string>>();
            
            //only need to get position once
            if (App.my_position == null && collect_geolocation)
            {
                PosDel success_del = PPos;

                Window.Navigator.Geolocation.GetCurrentPosition(success_del);
            }

            try
            {
                Tuple<string, string> position_timestamp = new Tuple<string, string>("position_timestamp", App.my_position.Timestamp.ToString());
                Tuple<string, string> latitude = new Tuple<string, string>("latitude", App.my_position.Coords.Latitude.ToString());
                Tuple<string, string> longitude = new Tuple<string, string>("longitude", App.my_position.Coords.Longitude.ToString());
                Tuple<string, string> pos_acc = new Tuple<string, string>("accuracy", App.my_position.Coords.Accuracy.ToString());
                user_data.Add(position_timestamp);
                user_data.Add(latitude);
                user_data.Add(longitude);
                user_data.Add(pos_acc);
            }
            catch
            {
                //if user doesnt accept
            }

            //Console.WriteLine(Window.Navigator.Oscpu);
            //Console.WriteLine(Window.Navigator.Platform);
            //Console.WriteLine(Window.Navigator.Product);
            //Console.WriteLine(Window.Navigator.Language);
            //Console.WriteLine(Window.Navigator.UserAgent);
            //Console.WriteLine(Window.Navigator.AppName);
            //Console.WriteLine(Window.Navigator.AppVersion);
            Tuple<string, string> Oscpu = new Tuple<string, string>("Oscpu", Window.Navigator.Oscpu);
            Tuple<string, string> Platform = new Tuple<string, string>("Platform", Window.Navigator.Oscpu);
            Tuple<string, string> Product = new Tuple<string, string>("Product", Window.Navigator.Oscpu);
            Tuple<string, string> Language = new Tuple<string, string>("Language", Window.Navigator.Oscpu);
            Tuple<string, string> UserAgent = new Tuple<string, string>("UserAgent", Window.Navigator.Oscpu);
            Tuple<string, string> AppName = new Tuple<string, string>("AppName", Window.Navigator.Oscpu);
            Tuple<string, string> AppVersion = new Tuple<string, string>("AppVersion", Window.Navigator.Oscpu);

            user_data.Add(Oscpu);
            user_data.Add(Platform);
            user_data.Add(Product);
            user_data.Add(Language);
            user_data.Add(UserAgent);
            user_data.Add(AppName);
            user_data.Add(AppVersion);

            return user_data;
        }

    }
}