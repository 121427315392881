using Bridge.Html5;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace StaticSiteToolbox.Controls
{
    public class ScrollPositioner
    {
        public List<HTMLDivElement> Sections { get; set; }
        public List<string> SectionNames { get; set; }

        public HTMLDivElement Content { get; set; }

        public string BackgroundColor { get; set; }
        public string Font { get; set; }
        public int FontSize { get; set; }
        public string FontColor { get; set; }

        public string CurrentSelection { get; set; }

        private HTMLOListElement scroller { get; set; }
        private HTMLDivElement container { get; set; }

        private double factor { get; set; }
        private string vertPadding { get; set; }

        private double desktop_factor = 2;
        private double mobile_factor = 1.33;

        private string desktop_vertPadding = "0";
        private string mobile_vertPadding = "20";

        private string scroll_btn_fontsize_speed = "0.5";
        private string scroll_speed = "1";

        public void Draw()
        {
            if (App.UseDesktop)
            {
                factor = desktop_factor;
                vertPadding = desktop_vertPadding;
            }
            else
            {
                factor = mobile_factor;
                vertPadding = mobile_vertPadding;
            }
            DrawContent();
        }

        private void DrawContent()
        {
            container = new HTMLDivElement
            {
                Style =
                {
                    Display = Display.InlineBlock,
                    Position = Position.Absolute,
                    Top = "0px",
                    Left = "0px",
                    Width = "100%",
                    Overflow = Overflow.Hidden,
                    Transition = "top " + scroll_speed + "s",
                    BackgroundColor = "rgba(0,0,0,0)",
                }
            };

            scroller = new HTMLOListElement
            {
                Style =
                {
                    BackgroundColor = "rgba(0,0,0,0)",
                    Right = "20px",
                    Top = vertPadding + "px",
                    Position = Position.Fixed,
                }
            };

            for (int s = 0; s < Sections.Count; s++)
            {
                container.AppendChild(Sections[s]);

                //create the button that can be clicked to scroll to position
                var scroll_nav_button = new HTMLButtonElement
                {
                    Id = SectionNames[s],
                    InnerHTML = SectionNames[s],
                    Style =
                    {
                        Display = Display.Block,
                        Color = FontColor,
                        BackgroundColor = "rgba(0,0,0,0)",
                        Border = "0px", //no border
                        FontSize = FontSize.ToString() + "px",
                        FontFamily = Font,
                        Transition = "font-size " + scroll_btn_fontsize_speed + "s",
                        TextAlign = TextAlign.Right,
                        Width = "100%"
                    },
                };

                if (scroll_nav_button.Id == CurrentSelection)
                {
                    scroll_nav_button.Style.FontSize = (FontSize * factor).ToString() + "px";
                }

                scroll_nav_button.OnMouseOver = (ev) =>
                {
                    if (scroll_nav_button.Id != CurrentSelection)
                    {
                        scroll_nav_button.Style.FontSize = (FontSize * factor).ToString() + "px";
                    }
                };

                scroll_nav_button.OnMouseLeave = (ev) =>
                {
                    if (scroll_nav_button.Id != CurrentSelection)
                    {
                        scroll_nav_button.Style.FontSize = FontSize.ToString() + "px";
                    }

                };

                //on nav button click
                scroll_nav_button.OnClick = (ev) =>
                {
                    //Console.WriteLine("clicked");
                    //un select current selection
                    var current_scroll_button = Document.GetElementById(CurrentSelection);
                    current_scroll_button.Style.FontSize = FontSize.ToString() + "px";

                    //assign new current selection
                    CurrentSelection = scroll_nav_button.Id;
                    //change text to hover color
                    scroll_nav_button.Style.FontSize = (FontSize * factor).ToString() + "px";

                    int sec = SectionNames.FindIndex((Predicate<string>)(x => x == CurrentSelection));

                    //Sections[sec].ScrollIntoView(false);

                    SmoothScroll(App.mainView.contentViewer.Content, Sections[sec]);
                };

                scroller.AppendChild(scroll_nav_button);
            }

            //scroller.Style.MinHeight = container.he
            RePosition();

            Footize();

            container.AppendChild(scroller);
            Content = container;
        }

        public void Footize()
        {
            if (App.site_params.general.UseFooter)
            {
                //draw the footer from site params and add to the scroller container
                var footer = App.site_params.footer;
                footer.Draw();
                container.AppendChild(footer.Content);
            }
        }

        public void RePosition()
        {
            int Top = 0;
            if (App.site_params.general.UseNavigationBar)
            {
                Top = (Window.InnerHeight / 3);//2) - ((Sections.Count * (FontSize)) / 2);
            }
            else
            {
                Top = (Window.InnerHeight / 3);//2) - ((Sections.Count * (FontSize)) / 2);
            }

            scroller.Style.Top = Top.ToString() + "px";
        }

        public void ShowSelection()
        {
            if (Sections != null)
            {
                try
                {
                    //get offheight of last element
                    int lastOffset = Sections[Sections.Count - 1].OffsetTop;
                    int pageHeight = lastOffset + Sections[Sections.Count - 1].ClientHeight;

                    //get scroll position
                    int scrolledPos = 0;
                    double scaler = 0;
                    double toadd = 0;
                    int scrollableHeight = 0;

                    scrollableHeight = Convert.ToInt16(App.mainView.contentViewer.Content.Style.Height.Substring(0, App.mainView.contentViewer.Content.Style.Height.Length - 2));
                    scrolledPos = App.mainView.contentViewer.Content.ScrollTop;
                    scaler = (double)scrolledPos / ((double)(Window.InnerHeight + pageHeight - (2 * scrollableHeight)));
                    toadd = scaler * scrollableHeight;

                    //find element that 
                    int sec_inside = Sections.FindIndex((Predicate<HTMLDivElement>)(x => x.OffsetTop >= (scrolledPos + toadd)));
                    bool noElementsAfter = (sec_inside == -1);
                    if (noElementsAfter)// && (scrolledPos > Sections[1].OffsetTop)) //not found and atleast past first
                    {
                        sec_inside = Sections.Count - 1;
                    }
                    //else if (!noElementsAfter && (scrolledPos >= (pageHeight - scrollableHeight)))
                    //{
                    //    sec_inside = Sections.Count - 1;
                    //}
                    else
                    {
                        sec_inside--;
                    }

                    //Console.Write("lastOffset:" + lastOffset + " ");
                    //Console.Write("pageHeight:" + pageHeight + " ");
                    //Console.Write("windowHeight:" + Window.InnerHeight + " ");
                    //Console.Write("scrollableHeight:" + scrollableHeight + " ");
                    //Console.Write("scrolledPos:" + scrolledPos + " ");
                    //Console.Write("scaler:" + scaler + " ");
                    //Console.WriteLine("toadd:" + toadd + " ");
                    //Console.WriteLine("selects: " + SectionNames[sec_inside] + " with offsetTop: " + Sections[sec_inside].OffsetTop);

                    var scroll_button = Document.GetElementById(SectionNames[sec_inside]);

                    if (scroll_button.Id != CurrentSelection)
                    {
                        var scroll_button_before = Document.GetElementById(CurrentSelection);
                        scroll_button_before.Style.FontSize = (FontSize).ToString() + "px";
                        scroll_button.Style.FontSize = (FontSize * factor).ToString() + "px";
                        CurrentSelection = scroll_button.Id;
                    }

                }
                catch// (Exception e)
                {
                    //Console.WriteLine(e.Message);
                    //Console.WriteLine(e.StackTrace);
                }

            }

        }

        private static void SmoothScroll(HTMLDivElement container, HTMLDivElement element)
        {
            //https://stackoverflow.com/questions/18078780/scroll-to-top-is-not-smooth-enough

            int scrollToPos = element.OffsetTop;

            var theContainer = Bridge.jQuery2.jQuery.Select(container);
            Bridge.Script.Eval("$(theContainer).animate({scrollTop:scrollToPos},500);"); //500 is animation time
        }


    }
}
