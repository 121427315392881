using Bridge.Html5;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace StaticSiteToolbox.Controls
{
    public class ImageCard : Card
    {
        public string ImageSrcMobile { get; set; }
        public string ImageSrcDesktop { get; set; }
        //public int contentFontSize { get; set; } //unused?
        //public int headerFontSize { get; set; } //unused?
        public bool RightHanded { get; set; }

        public string Header { get; set; }
        public string Content { get; set; }
        public int HeaderFontSize { get; set; }
        public int ContentFontSize { get; set; }
        public string FontColor { get; set; }
        public string Font { get; set; }
        public string BackgroundColor { get; set; }



        public new void Draw()
        {
            var image = new HTMLImageElement
            {
                Style =
                {
                    //Width = "100%",
                    //MinWidth = App.thin_width_change + "px",
                    //MaxWidth = "40%",
                    //MinWidth = "20%",
                    Display = Display.Block,
                    ObjectFit = ObjectFit.Contain,
                },
            };

            var header = new HTMLDivElement
            {
                InnerHTML = Header,
                Style =
                {
                    FontSize = HeaderFontSize.ToString() + "px",
                    Color = FontColor,
                    TextAlign = TextAlign.Left,
                    Padding = "10px",
                    FontFamily = Font,
                }
            };

            var content = new HTMLDivElement
            {
                InnerHTML = Content,
                Style =
                {
                    FontSize = ContentFontSize.ToString() + "px",
                    Color = FontColor,
                    TextAlign = TextAlign.Left,
                    Padding = "10px",
                    FontFamily = Font,
                    VerticalAlign = VerticalAlign.TextBottom,
                }
            };

            var content_wrapper = new HTMLDivElement
            {
                Style =
                {
                    Width = "100%",
                },
            };
            content_wrapper.AppendChild(header);
            content_wrapper.AppendChild(content);

            container = new HTMLDivElement
            {
                Style =
                {
                    BackgroundColor = BackgroundColor
                }
            };

            if (!App.UseDesktop)
            {
                image.Src = ImageSrcMobile;
                image.Style.MaxWidth = "320px";
                container.Style.Display = Display.Table;
                container.AppendChild(image);
                container.AppendChild(content_wrapper);
            }
            else
            {
                image.Src = ImageSrcDesktop;
                image.Style.MaxWidth = "320px";
                container.Style.Display = Display.Flex;
                if (RightHanded)
                {
                    container.AppendChild(image);
                    container.AppendChild(content_wrapper);
                }
                else
                {
                    content.Style.TextAlign = TextAlign.Right;
                    header.Style.TextAlign = TextAlign.Right;

                    container.AppendChild(content_wrapper);
                    container.AppendChild(image);
                }
            }

            Indent();
        }
    }
}
