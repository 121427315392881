using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Html5;

namespace StaticSiteToolbox.Models
{
    public class Navigation
    {
        public static string CurrentPageName { get; set; }

        public static void ChangeContentView(string newPageName)
        {
            //if page is DesktopMode
            if (newPageName == App.desktop_mode_indicator)
            {
                App.UseDesktop = true;
                newPageName = "";
            }

            //if no new page designated, use initial nav item
            if (newPageName == "")
            {
                newPageName = App.site_params.general.IndexPage;
            }
            
            //change the navigation current page name
            CurrentPageName = newPageName.Replace(" ", "");

            //add the hash in url
            //TODO: why replace spaces again?
            Window.Location.Hash = CurrentPageName.Replace(" ", "");

            //have the apps main view redraw its content
            App.Draw();
        }
        public static void Navigate()
        {
            ChangeContentView(Window.Location.Hash.Substr(1));
        }

    }
}
