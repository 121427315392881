using Bridge.Html5;
using Newtonsoft.Json;
using StaticSiteToolbox.Controls;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace StaticSiteToolbox.ViewModels
{
    public class DynamicCard
    {
        public string control { get; set; }
        public Dictionary<string, string> attributes { get; set; }
    }
    public class PageParams
    {
        public string ID { get; set; }
        public List<Dictionary<string, string>> cards { get; set; }
        public List<string> sectionNames { get; set; }
    }
    public class General
    {
        public int thin_width_change { get; set; }
        public string FontMain { get; set; }
        public string ColorFontPrimary { get; set; }
        public string ColorFontSecondary { get; set; }
        public string ColorPrimary { get; set; }
        public string ColorSecondary { get; set; }
        public int MainMarginPercent { get; set; }
        public int SecondaryMarginPercent { get; set; }
        public int BackupMarginPercent { get; set; }
        public int HeaderFontSize { get; set; }
        public int ContentFontSize { get; set; }
        public bool UseNavigationBar { get; set; }
        public string IndexPage { get; set; }
        public bool UseFooter { get; set; }
        public bool NavAboveContent { get; set; }
        public int PageLoadDelayMS { get; set; }
    }

    public class SiteParams
    {
        public General general { get; set; }
        public Footer footer { get; set; }
        public NavigationBar navbar { get; set; }
        public List<PageParams> pages { get; set; }
        public PageParams error_page { get; set; }
    }

    public class PageBuilder
    {
        //idea here is to create an object like HomePage or XavPage that is dynamically created 
        //like .Add('SimpleCard','ImHeader','Imcontenb')

        //why?
        //currently pages are hard coded objects
        //want them to be generated from the json
        public static Page Construct(string ID)
        {
            //inputs?
            //will have to be params

            //

            //list of cards for adding to page
            List<Card> cards = new List<Card>(); //prebuild using a ControlsBuilder?
            bool useScoller = true;
            List<string> sectionNames = new List<string>();

            //TODO: add this to PageParams so each page can control
            int scrollFontSize = 16;

            //first check to make sure the specified ID exists in the site_params.pages
            bool notFound = true;
            foreach (PageParams page_params in App.site_params.pages)
            {
                if (page_params.ID == ID)
                {
                    notFound = false;
                }
            }

            //if page not found, make it an error page
            if (notFound)
            {
                cards = CardBuilder.Construct(App.site_params.error_page.cards);
                sectionNames = App.site_params.error_page.sectionNames;
            }
            else //page was found
            {
                foreach (PageParams page_params in App.site_params.pages)
                {
                    if (page_params.ID == ID)
                    {
                        //construct the cards in the page
                        cards = CardBuilder.Construct(page_params.cards);

                        //and grab their names for the scroller
                        sectionNames = page_params.sectionNames;
                    }
                }
            }

            //the page that is being built
            Page page = new Page();

            //draw each card so its container is populated
            List<HTMLDivElement> scrollSections = new List<HTMLDivElement>();

            foreach (Card card in cards)
            {
                scrollSections.Add(card.container);

            }

            //add in the scroll positioner
            if (useScoller)
            {
                page.scrollPositioner = new ScrollPositioner()
                {
                    Sections = scrollSections,
                    SectionNames = sectionNames,
                    BackgroundColor = HTMLColor.Black,
                    Font = App.site_params.general.FontMain,
                    FontSize = scrollFontSize,
                    FontColor = HTMLColor.Gray,
                    CurrentSelection = "Top",
                    //Top = BannerHeight + App.LogoHeightDesktop,// + 30,
                };

                page.scrollPositioner.Draw();

                page.Draw();
                page.Content.AppendChild(page.scrollPositioner.Content);

                page.ResizeContent();
            }
            else
            {
                //page without scroller?
                Console.WriteLine("all pages must have a scroller?");
                //maybe also have scrollpositioner (it contains the footer too)
                //but have the section names be empty?
                //yes this is proper way ->['','']
            }
            return page;
        }

    }

    public class CardBuilder
    {
        public static List<Card> Construct(List<Dictionary<string, string>> dynamicCards)
        {
            List<Card> cards = new List<Card>();

            foreach (Dictionary<string, string> dynamicCard in dynamicCards)
            {
                cards.Add(Objectify(dynamicCard));
            }

            return cards;

        }

        static Card Objectify(Dictionary<string, string> dynamicCard)
        {

            switch (dynamicCard["control"])
            {
                case "BannerCard":
                    BannerCard bannerCard = new BannerCard
                    {
                        ImageSrcDesktop = dynamicCard["ImageSrcDesktop"],
                        ImageSrcMobile = dynamicCard["ImageSrcMobile"],
                        Header = dynamicCard["Header"],
                        Font = dynamicCard["Font"],
                        HeaderFontColor = dynamicCard["HeaderFontColor"],
                        HeaderFontSize = Convert.ToInt16(dynamicCard["HeaderFontSize"]),
                        MaxHeight = Convert.ToInt16(dynamicCard["MaxHeight"]),
                        MinWidth = Convert.ToInt16(dynamicCard["MinWidth"]),
                    };
                    bannerCard.Draw();
                    return bannerCard;
                case "SimpleCard":
                    SimpleCard simpleCard = new SimpleCard
                    {
                        Header = dynamicCard["Header"],
                        Content = dynamicCard["Content"],
                        FontColor = dynamicCard["FontColor"],
                        ContentFontSize = Convert.ToInt16(dynamicCard["ContentFontSize"]),
                        HeaderFontSize = Convert.ToInt16(dynamicCard["HeaderFontSize"]),
                        Font = dynamicCard["Font"],
                        BackgroundColor = dynamicCard["BackgroundColor"]
                    };
                    simpleCard.Draw();
                    return simpleCard;
                case "ImageCard":
                    ImageCard imageCard = new ImageCard
                    {
                        Header = dynamicCard["Header"],
                        Content = dynamicCard["Content"],
                        FontColor = dynamicCard["FontColor"],
                        ContentFontSize = Convert.ToInt16(dynamicCard["ContentFontSize"]),
                        HeaderFontSize = Convert.ToInt16(dynamicCard["HeaderFontSize"]),
                        Font = dynamicCard["Font"],
                        BackgroundColor = dynamicCard["BackgroundColor"],
                        ImageSrcDesktop = dynamicCard["ImageSrcDesktop"],
                        ImageSrcMobile = dynamicCard["ImageSrcMobile"],
                        RightHanded = Convert.ToBoolean(dynamicCard["RightHanded"].ToLower()),
                    };
                    imageCard.Draw();
                    return imageCard;

                case "LinkCard":
                    LinkCard linkCard = new LinkCard
                    {
                        Header = dynamicCard["Header"],
                        Content = dynamicCard["Content"],
                        PageNames = System.Linq.Enumerable.ToList<string>(dynamicCard["PageNames"].Split(',')),
                        Aliases = System.Linq.Enumerable.ToList<string>(dynamicCard["Aliases"].Split(',')),
                        HoverTextColor = dynamicCard["HoverTextColor"],
                        LinkTextColor = dynamicCard["LinkTextColor"],
                        FontColor = dynamicCard["FontColor"],
                        ContentFontSize = Convert.ToInt16(dynamicCard["ContentFontSize"]),
                        HeaderFontSize = Convert.ToInt16(dynamicCard["HeaderFontSize"]),
                        Font = dynamicCard["Font"],
                        BackgroundColor = dynamicCard["BackgroundColor"]
                    };
                    linkCard.Draw();
                    return linkCard;

                case "FormCard":
                    FormCard formCard = new FormCard
                    {
                        Header = dynamicCard["Header"],
                        Content = dynamicCard["Content"],
                        FontColor = dynamicCard["FontColor"],
                        ContentFontSize = Convert.ToInt16(dynamicCard["ContentFontSize"]),
                        HeaderFontSize = Convert.ToInt16(dynamicCard["HeaderFontSize"]),
                        Font = dynamicCard["Font"],
                        BackgroundColor = dynamicCard["BackgroundColor"],

                        //will have to makle assumptions on formatting
                        fields = System.Linq.Enumerable.ToList<string>(dynamicCard["fields"].Split(',')),// new List<string> { "Email: ", "New Password: ", "New Password re-entry: " },
                        //https://codereview.stackexchange.com/questions/60651/converting-a-boolean-list-to-string-and-vice-versa
                        required = System.Linq.Enumerable.Select<string,bool>(dynamicCard["required"].Split(','),(Func<string,bool>)(x => Convert.ToBoolean(x == "True"))).ToList(),//new List<bool> { true, true, true },

                        //inputTypes = new List<InputType> { InputType.Email, InputType.Password, InputType.Password },
                        //need a support function to feed
                        inputTypes = System.Linq.Enumerable.Select<string,InputType>(dynamicCard["inputTypes"].Split(','),(Func<string,InputType>)(x => ConvertToInputType(x))).ToList(),

                        //selections = new List<List<string>>()
                        //maybe split via ; then , ?
                        selections = GetSelection(System.Linq.Enumerable.ToList<string>(dynamicCard["selections"].Split(';'))),

                        IncludeSubmit = Convert.ToBoolean(dynamicCard["IncludeSubmit"]),

                        BtnText = dynamicCard["BtnText"],
                        BtnTextColor = dynamicCard["BtnTextColor"],
                        BtnTextHighlightColor = dynamicCard["BtnTextHighlightColor"],
                        BtnBackgroundColor = dynamicCard["BtnBackgroundColor"],

                        PostUrl = dynamicCard["PostUrl"],
                        ApiKeyVal = dynamicCard["ApiKeyVal"],
                        OnCompletePage = dynamicCard["OnCompletePage"],
                    };
                    formCard.Draw();
                    return formCard;

                case "ChargeCard":
                    ChargeCard chargeCard = new ChargeCard
                    {
                        Header = dynamicCard["Header"],
                        Content = dynamicCard["Content"],
                        FontColor = dynamicCard["FontColor"],
                        ContentFontSize = Convert.ToInt16(dynamicCard["ContentFontSize"]),
                        HeaderFontSize = Convert.ToInt16(dynamicCard["HeaderFontSize"]),
                        Font = dynamicCard["Font"],
                        BackgroundColor = dynamicCard["BackgroundColor"],

                        service = dynamicCard["service"],
                        //public_key = dynamicCard["public_key"],

                        AlertConfirmText = dynamicCard["AlertConfirmText"],
                        BtnText = dynamicCard["BtnText"],
                        BtnTextColor = dynamicCard["BtnTextColor"],
                        BtnTextHighlightColor = dynamicCard["BtnTextHighlightColor"],
                        BtnBackgroundColor = dynamicCard["BtnBackgroundColor"],

                        fields = System.Linq.Enumerable.ToList<string>(dynamicCard["fields"].Split(',')),

                        SelectedColor = dynamicCard["SelectedColor"],
                        SelectionTitles = System.Linq.Enumerable.ToList<string>(dynamicCard["SelectionTitles"].Split(',')),
                        SelectionContents = System.Linq.Enumerable.ToList<string>(dynamicCard["SelectionContents"].Split(',')),
                        SelectionPrices = System.Linq.Enumerable.Select<string,double>(dynamicCard["SelectionPrices"].Split(','),(Func<string,double>)(x => double.Parse(x))).ToList(),
                        SelectionIdentifiers = System.Linq.Enumerable.ToList<string>(dynamicCard["SelectionIdentifiers"].Split(',')),

                        PostUrl = dynamicCard["PostUrl"],
                        ApiKeyVal = dynamicCard["ApiKeyVal"],
                        OnCompletePage = dynamicCard["OnCompletePage"],

                    };
                    chargeCard.Draw();
                    return chargeCard;

                case "CardFilter":

                    List<SimpleCard> deck = new List<SimpleCard>();

                    Console.WriteLine("deck is...");
                    //Console.WriteLine(dynamicCard["Deck"]);
                    List<Dictionary<string, string>> dynamic_deck = JsonConvert.DeserializeObject<List<Dictionary<string, string>>>(dynamicCard["Deck"], new JsonSerializerSettings { NullValueHandling = NullValueHandling.Ignore, TypeNameHandling = TypeNameHandling.Objects });
                    foreach (Dictionary<string, string> dyn_card in dynamic_deck)
                    {
                        SimpleCard this_card = (SimpleCard)Objectify(dyn_card);
                        deck.Add(this_card);
                    }

                    CardFilter cardFilter = new CardFilter
                    {
                        Header = dynamicCard["Header"],
                        Content = dynamicCard["Content"],
                        FontColor = dynamicCard["FontColor"],
                        ContentFontSize = Convert.ToInt16(dynamicCard["ContentFontSize"]),
                        HeaderFontSize = Convert.ToInt16(dynamicCard["HeaderFontSize"]),
                        Font = dynamicCard["Font"],
                        BackgroundColor = dynamicCard["BackgroundColor"],

                        MethodContent = dynamicCard["MethodContent"],
                        MethodHeader = dynamicCard["MethodHeader"],
                        ButtonColor = dynamicCard["ButtonColor"],
                        ButtonText = dynamicCard["ButtonText"],
                        ButtonTextColor = dynamicCard["ButtonTextColor"],
                        Deck = deck,
                    };

                    cardFilter.Draw();
                    cardFilter.container.AppendChild(cardFilter.resultContainer);
                    return cardFilter;

                //submit button?
                case "ContainerCard":
                    //dynamicCard.attributes["cards"] is a json str with the cards contained
                    break;
                //unused?
                case "Input":
                    break;
                case "Link":
                    break;
            }
            return null;
        }
        public static InputType ConvertToInputType(string input)
        {
            switch (input)
            {
                case "Paragraph":
                    return (InputType)23;
                case "Text":
                    return InputType.Text;
                case "Email":
                    return InputType.Email;
                case "Radio":
                    return InputType.Radio;
                case "Password":
                    return InputType.Password;
                //many more than these 3
                default:
                    return InputType.Text;
            }
        }
        public static List<List<string>> GetSelection(List<string> input)
        {
            List<List<string>> selections = new List<List<string>>();

            foreach (string line in input)
            {
                //Console.WriteLine(line);
                if (line != "")
                {
                    selections.Add(new List<string>(line.Split(',')));
                }
                else
                {
                    selections.Add(new List<string>());
                }
            }

            return selections;
        }
    }
}
