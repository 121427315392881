using Bridge.Html5;
using StaticSiteToolbox.Models;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace StaticSiteToolbox.Controls
{
    public class NavigationBar
    {
        public static string AnchorTop = "Top";
        public static string AnchorRight = "Right";
        public static string AnchorLeft = "Left";
        public static string AnchorBottom = "Bottom";

        //attributes
        public string BackgroundColor { get; set; }
        public string NavTextColor { get; set; }
        public string HoverTextColor { get; set; }
        public string LogoSrcDesktop { get; set; }
        public string LogoSrcMobile { get; set; }
        public int LogoWidthDesktop { get; set; }
        public int LogoHeightDesktop { get; set; }
        public int LogoWidthMobile { get; set; }
        public int LogoHeightMobile { get; set; }
        public int LogoPadding { get; set; }
        public List<string> NavItems { get; set; }
        public string CurrentSelection { get; set; }
        //public bool MaskContentView { get; set; }
        public string Font { get; set; }
        public int FontSize { get; set; }
        public string Anchor { get; set; }
        //public int HeightPercent { get; set; }
        public int NavHeight { get; set; }
        public string HomePage { get; set; }

        //view containers
        public HTMLDivElement Content { get; set; }

        public void Draw()
        {
            if (App.UseDesktop)
            {
                Content = Draw_Desktop();
            }
            else
            {
                Content = Draw_Mobile();
            }
        }

        private HTMLDivElement Draw_Desktop()
        {
            //create logo image
            var logo = new HTMLImageElement
            {
                Src = LogoSrcDesktop,
                Width = LogoWidthDesktop,
                Height = LogoHeightDesktop,
                Style =
                {
                    PaddingLeft = LogoPadding.ToLocaleString() + "px",
                    Display = Display.Block,
                    MinWidth = LogoWidthDesktop.ToString() + "px",
                    Top = "0px",
                    Cursor = "pointer",
                },
            };

            logo.OnClick = (ev) =>
            {
                Navigation.ChangeContentView(HomePage);
            };

            //create list to contain navigation buttons
            var nav_list = new HTMLUListElement
            {
                Style =
                {
                    Display = Display.Table,
                    AlignItems = AlignItems.Stretch,
                    JustifyContent = JustifyContent.SpaceBetween,
                    AlignContent = AlignContent.FlexEnd,
                    VerticalAlign = VerticalAlign.TextTop,
                    Position = Position.Absolute,
                    Right = "0px",
                    Top = "0px"
                },
            };

            //make nav buttons
            foreach (string item in NavItems)
            {
                string line = item.Replace(" ", "");

                //create navigation button
                var nav_button = new HTMLButtonElement
                {
                    Id = line,
                    InnerHTML = item,
                    Style =
                    {
                        //Display = Display.InlineFlex,
                        Padding = "20px",
                        Color = NavTextColor,
                        BackgroundColor = "rgba(0,0,0,0)", //transparent
                        Border = "0px", //no border
                        FontSize = FontSize + "px",
                        Flex = "0 0 auto",
                        FontFamily = Font,
                        Cursor = "pointer",
                    },

                };

                //set text color of current selection
                if (nav_button.Id == Navigation.CurrentPageName)
                {
                    nav_button.Style.Color = HoverTextColor;
                }

                //add on hover event to change text color
                nav_button.OnMouseOver = (ev) =>
                {
                    nav_button.Style.Color = HoverTextColor;
                };
                nav_button.OnMouseLeave = (ev) =>
                {
                    if (nav_button.Id != Navigation.CurrentPageName)
                    {
                        nav_button.Style.Color = NavTextColor;
                    }

                };

                //add on click text color and current selection change
                nav_button.OnClick = (ev) =>
                {
                    nav_button.Style.Color = HoverTextColor;

                    foreach (HTMLButtonElement button in nav_list.Children)
                    {
                        if (button.Id == Navigation.CurrentPageName)
                        {
                            button.Style.Color = NavTextColor;
                        }
                    }

                    //change the content view to the new page clicked
                    Navigation.ChangeContentView(nav_button.Id);

                };

                nav_list.AppendChild(nav_button);

            }

            //create wrapper container so can position elements
            var nav_wrapper = new HTMLDivElement
            {
                Style =
                {
                    Display = Display.InlineFlex,
                    //AlignItems = AlignItems.Center,
                    JustifyContent = JustifyContent.FlexStart,

                    BackgroundColor = BackgroundColor, //assign background color
                    //Overflow = Overflow.Hidden, //not scrollable
                    Position = Position.Fixed, //fixed position
                    Top = "0px", //always stays on top of page
                    Left = "0px",
                    Width = "100%",
                    Height = (NavHeight).ToString() + "px"
                },
            };

            //add logo and nav list to wrapper
            nav_wrapper.AppendChild(logo);
            nav_wrapper.AppendChild(nav_list);


            return nav_wrapper;
        }

        private HTMLDivElement Draw_Mobile()
        {
            int HamFontSize = 20;
            int HamPad = 20;
            if (App.OnMobile)
            {
                LogoWidthMobile = LogoWidthDesktop * 2;
                LogoHeightMobile = LogoHeightMobile; // Window.InnerWidth / 5;
                FontSize = Window.InnerWidth / 20;
                HamFontSize = 120;
                HamPad = 75;
                LogoSrcMobile = LogoSrcDesktop;
                LogoPadding = 5;
                NavHeight = LogoHeightMobile; //NavHeight; // * 2;
            }

            //create logo image, and add to container
            var logo = new HTMLImageElement
            {
                Src = LogoSrcMobile,
                Width = LogoWidthMobile,
                Height = LogoHeightMobile,
                Style =
                {
                    PaddingLeft = LogoPadding.ToString() + "px",
                    Display = Display.Block,
                    //MinWidth = LogoHeightMobile.ToString() + "px",
                    MinHeight = LogoHeightMobile.ToString() + "px",
                    Top = "0px",
                    Cursor = "pointer",
                },
            };

            logo.OnClick = (ev) =>
            {
                Navigation.ChangeContentView(HomePage);
            };

            //create hamburger button
            var nav_hamburger = new HTMLButtonElement
            {
                InnerHTML = "=",
                Style =
                {
                    //Display = Display.InlineFlex,
                    Padding = HamPad + "px",
                    Color = HTMLColor.AliceBlue,//NavTextColor,
                    BackgroundColor = "rgba(0,0,0,0)", //transparent
                    Border = "0px", //no border
                    FontSize = HamFontSize + "px",
                    Flex = "0 0 auto",
                    Position = Position.Fixed,
                    Right = "0px",
                    //Top = "0px"
                    Outline = "none",
                    Cursor = "pointer",
                },

            };

            //create dropdown for hamburger button
            var nav_dropdown = new HTMLDivElement
            {
                Style =
                {
                    Visibility = Visibility.Hidden,
                    BoxShadow = "0px 0px 0px 0px rgba(0,0,0,0.2)",
                    Position = Position.Absolute,
                    Right = "0px",
                    Top = (LogoHeightMobile + 4).ToString() + "px",
                    Display = Display.Block,
                    BackgroundColor = BackgroundColor
                }
            };

            //events for showing dropdown
            bool dropdownVisible = false;
            nav_hamburger.OnClick = (ev) =>
            {
                if (dropdownVisible)
                {
                    nav_dropdown.Style.Visibility = Visibility.Hidden;
                    dropdownVisible = false;
                }
                else
                {
                    nav_dropdown.Style.Visibility = Visibility.Visible;
                    dropdownVisible = true;
                }

            };

            nav_dropdown.OnMouseLeave = (ev) =>
            {
                if (dropdownVisible)
                {
                    nav_dropdown.Style.Visibility = Visibility.Hidden;
                    dropdownVisible = false;
                }
                else
                {
                    nav_dropdown.Style.Visibility = Visibility.Visible;
                    dropdownVisible = true;
                }
            };


            //create list to contain hte navigation buttons
            var nav_list = new HTMLOListElement
            {
                Style =
                {
                    //Display = Display.Block
                    AlignItems = AlignItems.Stretch,
                    JustifyContent = JustifyContent.SpaceBetween,
                    AlignContent = AlignContent.FlexEnd,
                    VerticalAlign = VerticalAlign.TextTop,
                    //Width = "100%",
                    TextAlign = TextAlign.Right,
                    //Top = "0px"
                },
            };

            //create buttons for inside dropdown
            foreach (string item in NavItems)
            {
                string line = item.Replace(" ", "");
                var nav_button = new HTMLButtonElement
                {
                    Id = line,
                    InnerHTML = item,
                    Style =
                    {
                        Display = Display.Block,
                        TextAlign = TextAlign.Right,
                        Padding = "20px",
                        PaddingRight = "30px",
                        Color = NavTextColor,
                        BackgroundColor = "rgba(0,0,0,0)",
                        Border = "0px", //no border
                        FontSize = FontSize + "px",
                        Flex = "0 0 auto",
                        FontFamily = Font,
                        Cursor = "pointer",
                    },

                };

                if (nav_button.Id == Navigation.CurrentPageName)
                {
                    nav_button.Style.Color = HoverTextColor;
                }

                nav_button.OnMouseOver = (ev) =>
                {
                    nav_button.Style.Color = HoverTextColor;
                };

                nav_button.OnMouseLeave = (ev) =>
                {
                    if (nav_button.Id != Navigation.CurrentPageName)
                    {
                        nav_button.Style.Color = NavTextColor;
                    }

                };

                //on nav button click
                nav_button.OnClick = (ev) =>
                {
                    //change text to hover color
                    nav_button.Style.Color = HoverTextColor;

                    //reset previous selection hover color
                    foreach (HTMLButtonElement button in nav_list.Children)
                    {
                        if (button.Id == Navigation.CurrentPageName)
                        {
                            button.Style.Color = NavTextColor;
                        }
                    }

                    //change the content view to the new page clicked
                    Navigation.ChangeContentView(nav_button.Id);

                };

                nav_list.AppendChild(nav_button);

            }

            var nav_wrapper = new HTMLDivElement
            {
                Style =
                {
                    Display = Display.InlineFlex,
                    AlignItems = AlignItems.Center,
                    JustifyContent = JustifyContent.FlexStart,
                    BackgroundColor = BackgroundColor, //assign background color
                    Position = Position.Fixed, //fixed position
                    Top = "0px", //always stays on top of page
                    Left = "0px",
                    Width = "100%",
                    Height = NavHeight.ToString() + "px"
                },
            };

            //
            nav_dropdown.AppendChild(nav_list);

            nav_wrapper.AppendChild(logo);
            nav_wrapper.AppendChild(nav_hamburger);

            nav_wrapper.AppendChild(nav_dropdown);


            nav_wrapper.Style.Top = "0px"; //TODO: anchor



            return nav_wrapper;
        }
    }
}
