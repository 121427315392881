using Bridge.Html5;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace StaticSiteToolbox.Controls
{
    public class CardFilter : SimpleCard
    {
        //public string Header { get; set; }
        //public string Content { get; set; }
        //public int HeaderFontSize { get; set; }
        //public int ContentFontSize { get; set; }
        //public string FontColor { get; set; }
        //public string Font { get; set; }
        public string MethodHeader { get; set; }
        public string MethodContent { get; set; }
        public string ButtonText { get; set; }
        public string ButtonColor { get; set; }
        public string ButtonTextColor { get; set; }

        public HTMLDivElement resultContainer { get; set; }

        public List<SimpleCard> Deck { get; set; }

        public static string filterStr = "";

        public List<HTMLDivElement> Results { get; set; }

        private bool doing_redraw = false;

        public void Filter()
        {
            //Console.WriteLine(filterStr);
            if (filterStr.Length < 1)
            {
                foreach (HTMLDivElement result in Results)
                {
                    result.Style.Visibility = Visibility.Visible;
                    result.Style.Opacity = "1";
                }
                return;
            }


            string[] keywords = filterStr.Split(' ');

            List<int> scores = new List<int>();

            for (int card_idx = 0; card_idx < Deck.Count; card_idx++)
            {
                int score = 0;
                foreach (string keyword in keywords)
                {
                    if ((Deck[card_idx].Header + " " + Deck[card_idx].Content).ToLower().Contains(keyword))
                    {
                        score = score + 1;
                    }
                }
                scores.Add(score);
            }

            List<SimpleCard> FilteredDeck = new List<SimpleCard>();

            int highest_score = scores.Max();
            for (int s = highest_score; s > 0; s--)
            {

                List<int> foundCard_idz = new List<int>();
                for (int c = 0; c < Deck.Count; c++)
                {
                    if (scores[c] == s)
                    {
                        foundCard_idz.Add(c);
                    }
                }

                if (foundCard_idz.Count > 0)
                {
                    foreach (int foundCard_idx in foundCard_idz)
                    {
                        Results[foundCard_idx].Style.Visibility = Visibility.Visible;
                        Results[foundCard_idx].Style.Opacity = "1";
                        Results[foundCard_idx].Style.PaddingBottom = "40px";
                    }
                }
            }
        }

        new public void Draw()
        {
            var methodHeader = new HTMLDivElement
            {
                InnerHTML = MethodHeader,
                Style =
                {
                    FontSize = HeaderFontSize.ToString() + "px",
                    Color = FontColor,
                    TextAlign = TextAlign.Left,
                    Padding = "10px",
                    FontFamily = Font
                }
            };

            var methodContent = new HTMLDivElement
            {
                InnerHTML = MethodContent,
                Style =
                {
                    FontSize = ContentFontSize.ToString() + "px",
                    Color = FontColor,
                    TextAlign = TextAlign.Left,
                    Padding = "10px",
                    FontFamily = Font
                }
            };

            var input = new HTMLInputElement
            {
                Type = InputType.Search,
                Style =
                {

                    FontSize = ContentFontSize.ToString() + "px",
                    //Color = ButtonColor,
                    TextAlign = TextAlign.Left,
                    Padding = "10px",
                    FontFamily = Font,
                    MaxWidth = "500px"
                }
            };
            if (App.OnMobile)
            {
                input.Style.FontSize = HeaderFontSize.ToString() + "px";

            }

            var button = new HTMLButtonElement
            {
                InnerHTML = ButtonText,
                Style =
                {
                    FontSize = ContentFontSize.ToString() + "px",
                    BackgroundColor = ButtonColor,
                    Color = ButtonTextColor,
                    TextAlign = TextAlign.Center,
                    Padding = "10px",
                    FontFamily = Font,
                    //Position = Position.Absolute,
                    //Right = "0px"
                    
                }
            };

            var header = new HTMLDivElement
            {
                InnerHTML = Header,
                Style =
                {
                    FontSize = HeaderFontSize.ToString() + "px",
                    Color = FontColor,
                    TextAlign = TextAlign.Left,
                    Padding = "10px",
                    FontFamily = Font
                }
            };

            var content = new HTMLDivElement
            {
                InnerHTML = Content,
                Style =
                {
                    FontSize = ContentFontSize.ToString() + "px",
                    Color = FontColor,
                    TextAlign = TextAlign.Left,
                    Padding = "10px",
                    FontFamily = Font
                }
            };

            container = new HTMLDivElement
            {
                Style =
                {
                    Display = Display.Block,
                    //Transition = "height 1s"
                    BackgroundColor = BackgroundColor,
                }
            };

            container.AppendChild(methodHeader);
            container.AppendChild(methodContent);
            container.AppendChild(input);
            //container.AppendChild(button); //REMOVED button as it auto compeltes when typing

            resultContainer = new HTMLDivElement
            {
                Style =
                {
                    Display = Display.Table,
                    //PaddingLeft = "5px",
                    //Margin = "0px",
                    Height = "100%",
                    Width = "100%",
                    BackgroundColor = BackgroundColor,
                    PaddingTop = "20px",
                }
            };

            Results = new List<HTMLDivElement>();
            foreach (SimpleCard card in Deck)
            {
                card.Draw();
                card.container.Style.Visibility = Visibility.Visible;
                card.container.Style.Opacity = "1";
                card.container.Style.Transition = "visibility 0.5s, opacity 0.5s";
                card.container.Style.PaddingTop = "20px";
                card.container.Style.PaddingBottom = "20px";
                card.container.Style.PaddingLeft = "5px";
                card.container.Style.PaddingRight = "5px";
                card.container.Style.Width = "100%";
                Results.Add(card.container);
            }

            Filter();
            foreach (HTMLDivElement result in Results)
            {
                resultContainer.AppendChild(result);
            }

            //container.AppendChild(resultContainer);

            //button.OnClick = (ev) =>
            //{
            //    foreach (HTMLDivElement result in Results)
            //    {
            //        result.Style.Visibility = Visibility.Collapse;
            //        result.Style.Opacity = "0";
            //        result.Style.PaddingBottom = "0px";
            //        result.Style.PaddingTop = "0px";
            //    }

            //    filterStr = input.Value.ToLower();
            //    Filter();
            //};

            input.OnKeyDown = (ev) =>
            {
                //if (ev.Key == "Enter")
                //{
                //    foreach (HTMLDivElement result in Results)
                //    {
                //        result.Style.Visibility = Visibility.Collapse;
                //        result.Style.Opacity = "0";
                //        result.Style.PaddingBottom = "0px";
                //        result.Style.PaddingTop = "0px";
                //    }

                //    filterStr = input.Value.ToLower();
                //    Filter();

                //    Document.ActiveElement.Blur();
                //}
                
                if (!doing_redraw)
                {
                    doing_redraw = true;
                    foreach (HTMLDivElement result in Results)
                    {
                        result.Style.Visibility = Visibility.Collapse;
                        result.Style.Opacity = "0";
                        result.Style.PaddingBottom = "0px";
                        result.Style.PaddingTop = "0px";
                    }

                    filterStr = input.Value.ToLower();
                    Filter();
                    doing_redraw = false;
                }
                
                
            };

            Indent();


            if (App.OnMobile)
            {
                button.Style.Visibility = Visibility.Collapse;
            }

        }
    }
}
