using Bridge.Html5;
using StaticSiteToolbox.Models;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace StaticSiteToolbox.Controls
{
    class ChargeCard : Card
    {
        public string Header { get; set; }
        public string Content { get; set; }
        public int HeaderFontSize { get; set; }
        public int ContentFontSize { get; set; }
        public string FontColor { get; set; }
        public string Font { get; set; }
        public string BackgroundColor { get; set; }

        public List<string> fields { get; set; }
        //public List<bool> required { get; set; }
        //public List<List<string>> selections { get; set; }
        //public List<Bridge.Html5.InputType> inputTypes { get; set; }


        public string service { get; set; } //'stripe' -> this is requiredto indicate code for submitting to various services

        //ok so instead have things like
        public string AlertConfirmText { get; set; } //text to include in alert pop upon upon submit

        //stylize the submit button very much like the link card
        public string BtnBackgroundColor { get; set; } //background color
        public string BtnTextColor { get; set; } //text in button color
        public string BtnTextHighlightColor { get; set; } //color when button mouseover
        public string BtnText { get; set; } //text in submit button

        public string SelectedColor { get; set; }
        
        public List<string> SelectionTitles { get; set; }
        //    = new List<string>
        //{
        //    "1 Months",
        //    "2 Months",
        //    "3 Months"
        //};

        public List<string> SelectionContents { get; set; }
        //    = new List<string>
        //{
        //    "Good deal",
        //    "Better deal",
        //    "Best deal",
        //};

        public List<double> SelectionPrices { get; set; }
        //    = new List<double>
        //{
        //    5.00,
        //    9.00,
        //    12.00,
        //};

        public List<string> SelectionIdentifiers { get; set; }

        public string PostUrl { get; set; }
        public string ApiKeyVal { get; set; }


        public string ShadowColor = "darkgray";
        public string HighlightColor = "dimgray";
        public string OnCompletePage = "XavSignupCompleted";

        private bool CollectUserDataOnSubmit = true;
        private bool CollectGeolocation = false;

        //select price card value
        private double selected_price;
        private string selected_identifier;

        //DEPRACATED
        //public string public_key { get; set; }

        //idea here is to create a form for charging a card
        //depending on service selected (stripe.js)

        //a product is selected from options
        //submission and then aws lambda callback
        //then present a response pop up?

        //option 1 - 

        //card which uses stripe js elements to accept credit card payment

        //essentially use stripe elements to build like input
        //https://stripe.com/docs/stripe-js/reference
        //https://www.youtube.com/watch?v=Vw-RuREf_sM

        //1 create a DOM container

        //2 create a label for the container

        //3 create the card element
        //https://stripe.com/docs/stripe-js/reference#elements-create

        //4 mount the card element to the labelled container
        //https://stripe.com/docs/stripe-js/reference#element-mount

        //ideally, only collect email and preferred communication
        //such as snapchat/handle or instagram/handle

        public void when_card_changes(List<HTMLDivElement> card_containers)
        {
            foreach (HTMLDivElement con in card_containers)
            {
                var inp = (HTMLInputElement)con.Children[0];

                var clab = (HTMLLabelElement)con.Children[1];
                var cpcon = (HTMLDivElement)clab.Children[2];
                //var cpcon = (HTMLInputElement)clab.Children[2];

                //Console.WriteLine(con.Id + " checked: " + inp.Checked.ToString());
                //Console.WriteLine("cur color: " + con.Style.BorderColor);

                //string[] splits = con.Id.Split(new string[] { "card_container" }, StringSplitOptions.None);
                //int card_index = Convert.ToInt64(splits[splits.Length - 1]);

                int card_index = Convert.ToInt16(con.Id);

                if (inp.Checked)
                {
                    con.Style.BorderColor = SelectedColor;
                    selected_price = SelectionPrices[card_index];
                    selected_identifier = SelectionIdentifiers[card_index];
                    cpcon.Style.Color = SelectedColor;
                    clab.Style.Color = SelectedColor;
                }
                else
                {
                    con.Style.BorderColor = ShadowColor;
                    cpcon.Style.Color = ShadowColor;
                    clab.Style.Color = ShadowColor; // default html color
                }
                //Console.WriteLine("changed to: " + con.Style.BorderColor);
            }
        }

        //OK
        new public void Draw()
        {
            container = new HTMLDivElement
            {
                Style =
                {
                    Display = Display.Block,
                    Height = "100%",
                    BackgroundColor = BackgroundColor
                }
            };

            var header = new HTMLDivElement
            {
                InnerHTML = Header,
                Style =
                {
                    FontSize = HeaderFontSize.ToString() + "px",
                    Color = FontColor,
                    TextAlign = TextAlign.Left,
                    Padding = "10px",
                    FontFamily = Font
                }
            };

            var content = new HTMLDivElement
            {
                InnerHTML = Content,
                Style =
                {
                    FontSize = ContentFontSize.ToString() + "px",
                    Color = FontColor,
                    TextAlign = TextAlign.Left,
                    Padding = "10px",
                    FontFamily = Font
                }
            };

            container.AppendChild(header);
            container.AppendChild(content);

            List<HTMLDivElement> card_containers = new List<HTMLDivElement>();

            var minicards = new HTMLDivElement
            {
                ClassName = "row",
                Style =
                {
                    Display = Display.Flex,
                    //BorderSpacing = "50px"
                    Width = "100%"
                }
            };

            for (int i = 0; i < SelectionTitles.Count; i++)
            {
                HTMLDivElement card_header = new HTMLDivElement
                {
                    InnerHTML = "<strong>" + SelectionTitles[i] + "</strong><br>",
                    Style =
                    {
                        FontSize = (HeaderFontSize - 4).ToString() + "px",
                        Cursor = "pointer",
                        FontFamily = Font,
                    }
                };
                HTMLDivElement card_content = new HTMLDivElement
                {
                    InnerHTML = SelectionContents[i],
                    Style =
                    {
                        FontSize = (ContentFontSize - 4).ToString() + "px",
                        Cursor = "pointer",
                        FontFamily = Font,
                    }
                };
                HTMLDivElement card_price_container = new HTMLDivElement
                {
                    Style =
                    {
                        FontSize = (HeaderFontSize).ToString() + "px",
                        Cursor = "pointer",
                        PaddingTop = "20px",
                        Display = Display.InlineFlex,
                        Color = ShadowColor,
                        FontFamily = Font,
                    }
                };
                HTMLDivElement card_price_sign = new HTMLDivElement
                {
                    InnerHTML = "$",
                    Style =
                    {
                        FontSize = (HeaderFontSize - 4).ToString() + "px",
                        Cursor = "pointer",
                        FontFamily = Font,
                    }
                };
                HTMLDivElement card_price_val = new HTMLDivElement
                {
                    InnerHTML = "<strong>" + SelectionPrices[i].ToString() + "</strong>",
                    Style =
                    {
                        FontSize = (HeaderFontSize + 4).ToString() + "px",
                        Cursor = "pointer",
                        FontFamily = Font,
                    }
                };

                var cardlabel = new HTMLLabelElement
                {
                    Id = "cardlabel" + i.ToString(),
                    HtmlFor = "card_input" + i.ToString(),
                    //InnerHTML = "<font size=\"" + HeaderFontSize + "\"><strong>" + card_titles[i] + "</strong><br><font size=\"" + ContentFontSize + "\">text label<br>for card" + i.ToString(),
                    Style =
                    {
                        Cursor = "pointer",
                        Color = ShadowColor,
                        FontFamily = Font,
                        //PaddingLeft = "20px",
                        
                        
                        //Width = "100%",//((100.0 / card_titles.Count) - 0.1).ToString() + "%",
                        //Height = "100%",
                        //BorderStyle = "solid",
                        //BorderWidth = "50px",
                        //BorderColor = "rgba(50,50,50,1)"
                        //Display = Display.Flex
                    }
                };

                cardlabel.AppendChild(card_header);
                cardlabel.AppendChild(card_content);


                card_price_container.AppendChild(card_price_sign);
                card_price_container.AppendChild(card_price_val);


                cardlabel.AppendChild(card_price_container);

                //add the radio selection mini cards
                var card_input = new HTMLInputElement
                {
                    Type = InputType.Radio,
                    Name = "minicards",
                    Checked = true,
                    Value = SelectionTitles[i],
                    //Placeholder = "im card 1<br>see",
                    Width = 100,
                    Id = "card_input" + i.ToString(),
                    Style =
                    {
                        Position = Position.Absolute,
                        Opacity = "0",
                        Width = "0px",
                        Height = "0px",
                        FontFamily = Font,
                    }
                };

                var card_container = new HTMLDivElement
                {
                    //Id = "card_container" + i.ToString(),
                    Id = i.ToString(),
                    //ClassName = "row",

                    Style =
                    {
                        //Display = Display.InlineBlock,
                        //BorderSpacing = "50px"
                        //CssFloat = "left",
                        Width = "32%", //((100.0 / card_titles.Count) - 0.1).ToString() + "%",
                        PaddingTop = "30px",
                        PaddingBottom = "30px",
                        PaddingLeft = "5px",
                        BorderStyle = "solid",
                        BorderBottomWidth = "8px",
                        BorderRightWidth = "6px",
                        BorderTopWidth = "1px",
                        BorderLeftWidth = "1px",
                        BorderColor = ShadowColor,
                        Cursor = "pointer",
                        BorderRadius = "5px",
                        Margin = "5px",
                        FontFamily = Font,
                    }
                };
                
                card_container.AppendChild(card_input);
                card_container.AppendChild(cardlabel);
                minicards.AppendChild(card_container);
                card_containers.Add(card_container);
            }

            container.AppendChild(minicards);

            foreach(HTMLDivElement con in card_containers)
            {
                var inp = (HTMLInputElement)con.Children[0];

                con.OnMouseUp = (ev) =>
                {
                    inp.Checked = true;
                    //con.Style.BorderColor = selected_color;
                    when_card_changes(card_containers);
                };

                con.OnMouseOver = (ev) =>
                {
                    con.Style.BorderColor = HighlightColor;
                };

                con.OnMouseLeave = (ev) =>
                {
                    if (!inp.Checked)
                    {
                        con.Style.BorderColor = ShadowColor;
                    }
                    else
                    {
                        con.Style.BorderColor = SelectedColor;
                    }
                    //when_card_changes(card_containers);
                };

                //inp.OnChange = (ev) =>
                //{
                //    when_card_changes(card_containers);
                //};
            }

            //set the final card as selected color (when created the final one ends up selected
            card_containers[card_containers.Count - 1].Style.BorderColor = SelectedColor;

            //set the final card price container color to selected
            card_containers[card_containers.Count - 1].Children[1].Children[2].Style.Color = SelectedColor;
            card_containers[card_containers.Count - 1].Children[1].Style.Color = SelectedColor;


            //also set the final card as the selecte price
            selected_price = SelectionPrices[Convert.ToInt16(card_containers[card_containers.Count - 1].Id)];
            selected_identifier = SelectionIdentifiers[Convert.ToInt16(card_containers[card_containers.Count - 1].Id)];

            //TODO: add onchecge for the inpt name

            switch (service)
            {
                case "stripe":

                    //TODO: remove the hard coded margins/padding and centralize them
                    //so can just tweak a var here rather than have to go through each until everything lines up

                    //div container
                    var card_container = new HTMLDivElement
                    {
                        //InnerHTML = "Card Input:", //doesnt show/is overwritten
                        Style =
                        {
                            //PaddingTop = "30px",
                            PaddingBottom = "30px",
                        }
                    };
                    var image = new HTMLImageElement
                    {
                        Src = "images/powered_by_stripe.png",
                        Style =
                        {
                            Width = "100px",
                            //MinWidth = App.thin_width_change + "px",
                            //MaxWidth = "40%",
                            //MinWidth = "20%",
                            //Display = Display.Block,
                            //ObjectFit = ObjectFit.Contain,
                            //Position = Position.Absolute,
                            //Visibility = Visibility.Visible,
                            MarginTop = "30px",
                            MarginBottom = "-12px",
                            MarginLeft = "10px",
                            Border = "2px",
                            BorderColor = ShadowColor,//"rgba(100,100,100,1)",
                            BorderStyle = "solid",
                            BorderRadius = "5px"

                        },
                    };
                    var card_container_inner = new HTMLDivElement
                    {
                        //InnerHTML = "Card Input:",
                        Style =
                        {
                            //BorderSpacing = "10px",
                            Padding = "30px",
                            //Margin = "30px",
                            Border = "2px",
                            BorderColor = ShadowColor,//"rgba(100,100,100,1)",
                            BorderStyle = "solid",
                            BorderRadius = "8px",
                            Width = "90%",
                            Display = Display.Table,
                        }
                    };
                    var card_element_container = new HTMLDivElement
                    {
                        //Id = "card-element",
                        InnerHTML = "Card Input:",
                        Style =
                        {
                            //Padding = "30px",
                            //MarginTop = "5px",
                            MarginRight = "10px",
                            //Border = "10px",
                            //BorderColor = "rgba(255,255,255,1)",
                            //BorderStyle = "solid",
                            FontFamily = Font,
                            //Width = "100%"
                        }
                    };

                    //div container that will mount the stripe iframe
                    var card_element = new HTMLDivElement
                    {
                        Id = "card-element",
                        //InnerHTML = "Card Input:", //doesnt show/is overwritten
                        Style =
                        {
                            Padding = "15px",
                            MarginTop = "5px",
                            //MarginRight = "10px",
                            Border = "1px",
                            BorderColor = "gray",
                            BorderStyle = "solid",
                            Width = "100%"
                        }
                    };

                    //add billing info collectionss
                    //dont need state/zip as zipcode is colelcted in stripe iframe and determines state
                    List<string> billing_items_required = global::Bridge.Script.CallFor(new List<string>(),(_o1)=>{_o1.Add("Cardholder Name");_o1.Add("Street Address");return _o1;});
                    List<InputType> billing_items_types = global::Bridge.Script.CallFor(new List<InputType>(),(_o2)=>{_o2.Add(InputType.Text);_o2.Add(InputType.Text);return _o2;});
                    List<string> billing_items_prompts = global::Bridge.Script.CallFor(new List<string>(),(_o3)=>{_o3.Add("Full name as it appears on the card");_o3.Add("123 Street Ave");return _o3;});
                    for (int i = 0; i < billing_items_required.Count; i++)
                    {
                        var billing_collect_container = new HTMLDivElement
                        {
                            InnerHTML = billing_items_required[i] + ":",
                            Style =
                            {
                                FontFamily = Font,
                                Display = Display.TableRow,
                                //Padding = "50px",
                                //PaddingTop = "10px",
                                //PaddingRight = "30px",
                                //MarginRight = "30px",
                                Width = "100%"
                            }
                        };
                        var input = new HTMLInputElement
                        {
                            Type = billing_items_types[i],
                            Required = true,
                            Id = billing_items_required[i],//"billing_" + i.ToString(),
                            Placeholder = billing_items_prompts[i],
                            Style =
                            {
                                FontSize = ContentFontSize.ToString() + "px",
                                Color = FontColor,
                                TextAlign = TextAlign.Left,
                                Padding = "10px",
                                //Margin = "20px",
                                FontFamily = Font,
                                //MinWidth = "25px",
                                //MinHeight = "15px",
                                Width = "100%",
                                MarginTop = "5px",
                                MarginBottom = "5px",
                                //MarginRight = "30px",
                                //PaddingTop = "10px",
                                //PaddingBottom = "10px",
                            }
                        };
                        billing_collect_container.AppendChild(input);

                        card_container_inner.AppendChild(billing_collect_container);
                    }

                    card_element_container.AppendChild(card_element);
                    card_container_inner.AppendChild(card_element_container);

                    container.AppendChild(image);

                    card_container.AppendChild(card_container_inner);

                    //var card_label = new HTMLLabelElement
                    //{
                    //    HtmlFor = "card-element",
                    //    InnerHTML = "Credit or debit card",
                    //};

                    var error_container = new HTMLDivElement
                    {
                        Id = "card-errors",
                        //role
                    };

                    var submission_container = new HTMLDivElement
                    {
                        Style =
                        {
                            Display = Display.InlineFlex,
                            Width = "100%",
                            AlignItems = AlignItems.Center,
                        }
                    };

                    //submit button
                    var submit_button = new HTMLButtonElement
                    {
                        Id = "submit_button",
                        InnerHTML = "<strong>" + BtnText + "</strong>",
                        Style =
                        {
                            Padding = "20px",
                            Color = BtnTextColor,
                            BackgroundColor = BtnBackgroundColor,
                            Border = "0px", //no border
                            FontSize = ContentFontSize.ToString() + "px",
                            Flex = "0 0 auto",
                            FontFamily = Font,
                            BorderRadius = "5px",
                            Cursor = "pointer",
                        }
                    };

                    //status text div
                    var status = new HTMLDivElement
                    {
                        Id = "status_div",
                        InnerHTML = "Status goes here",
                        Style =
                        {
                            FontSize = "20".ToString() + "px",
                            Color = App.site_params.general.ColorFontSecondary,
                            TextAlign = TextAlign.Left,
                            Padding = "10px",
                            FontFamily = App.site_params.general.FontMain,
                            Width = "50%",
                            Overflow = Overflow.Visible,
                        }
                    };

                    //status image div
                    var status_image = new HTMLImageElement
                    {
                        Id = "status_image_div",
                        //InnerHTML = " ",
                        Src = "images/nothing_status.png",
                        Style =
                        {
                            //FontSize = "20".ToString() + "px",
                            Color = App.site_params.general.ColorFontSecondary,
                            //TextAlign = TextAlign.Center,
                            //Padding = "10px",
                            //FontFamily = App.site_params.general.FontMain,
                            Display = Display.InlineBlock,
                            ObjectFit = ObjectFit.Contain,
                            Width = "32px",
                            Height = "32px",
                            //Height = "100%",
                        }
                    };

                    //event to call to update the status items
                    status.OnChange = (ev) =>
                    {
                        //have to try because innerhtml may not have double quotes so indexing fails
                        if (status.InnerHTML.Contains("\""))
                        {
                            if (status.InnerHTML.Split('"')[1] == OnCompletePage)
                            {
                                //assume success?
                                status_image.Src = "images/success_status.png";

                                //navigate to the completed page
                                Navigation.ChangeContentView(OnCompletePage);
                            }
                            else
                            {
                                //Console.WriteLine("1changing submit btn disabled to false");
                                submit_button.Disabled = false;
                            }
                        }
                        else
                        {
                            //Console.WriteLine("2changing submit btn disabled to false");
                            submit_button.Disabled = false;
                        }
                    };

                    //container div to hold the form json
                    var form_json_container = new HTMLDivElement
                    {
                        Id = "form_jsonstr",
                        InnerHTML = "",
                        Style =
                        {
                            Display = "none"
                        }
                    };

                    //add on hover event to change text color
                    submit_button.OnMouseOver = (ev) =>
                    {
                        submit_button.Style.Color = BtnTextHighlightColor;
                    };
                    submit_button.OnMouseLeave = (ev) =>
                    {
                        submit_button.Style.Color = BtnTextColor;
                    };
                    submit_button.OnClick = (ev) =>
                    {
                        //Console.WriteLine("on clicking Disabled: " + submit_button.Disabled.ToString());
                        //set the submit button to disabled so cant be presssed again until response
                        submit_button.Disabled = true;
                        status_image.Src = "images/loading_status.png";
                        //Console.WriteLine("-> Disabled: " + submit_button.Disabled.ToString());

                        //reset the form container and form json string
                        string form_json = ",";
                        status.InnerHTML = "Submitting...";
                        form_json_container.InnerHTML = "";

                        //collect the user browser data, if specified
                        List<Tuple<string, string>> user_data = new List<Tuple<string, string>>();
                        if (CollectUserDataOnSubmit)
                        {
                            if (App.my_position == null && CollectGeolocation)
                            {
                                //TODO: make a pop up instead
                                status.InnerHTML = "You must allow your location to be shared in order to complete this purchase.";
                                user_data = App.CollectUserData(CollectGeolocation);
                                submit_button.Disabled = false;
                                return;
                            }

                            user_data = App.CollectUserData(CollectGeolocation);
                            foreach (Tuple<string,string> pair in user_data)
                            {
                                form_json = form_json + "\"" + pair.Item1 + "\":\"" + pair.Item2 + "\",";
                            }
                        }
                        
                        //TODO: collect the billing container items!?!?!
                        if (billing_items_required.Count > 0)
                        {
                            bool billing_form_incomplete = false;

                            foreach (string billing_item in billing_items_required)
                            {
                                var this_input = Document.GetElementById<HTMLInputElement>(billing_item);

                                if (this_input.Required)
                                {
                                    //check if filled out 
                                    if (!this_input.Validity.Valid)
                                    {
                                        //if not color its border red
                                        this_input.Style.Border = "2px";
                                        this_input.Style.BorderColor = "red";
                                        this_input.Style.BorderStyle = "solid";

                                        //mark as form incomplete
                                        billing_form_incomplete = true;
                                    }
                                    else
                                    {
                                        //reset border back to default
                                        this_input.Style.Border = "";
                                        this_input.Style.BorderColor = "";
                                        this_input.Style.BorderStyle = "";
                                    }
                                }

                                string value = this_input.Value;
                                form_json = form_json + "\"" + billing_item + "\":\"" + value + "\",";
                            }

                            if (billing_form_incomplete)
                            {
                                status.InnerHTML = "Please complete the required form elements!";
                                status_image.Src = "images/error_status.png";
                                submit_button.Disabled = false;
                                return;
                            }
                        }

                        //collect information from form card
                        if (fields.Count > 0)
                        {
                            bool form_incomplete = false;

                            foreach (string field in fields)
                            {
                                var this_input = Document.GetElementById<HTMLInputElement>(field);

                                if (this_input.Required)
                                {
                                    //check if filled out 
                                    if (!this_input.Validity.Valid)
                                    {
                                        //if not color its border red
                                        this_input.Style.Border = "2px";
                                        this_input.Style.BorderColor = "red";
                                        this_input.Style.BorderStyle = "solid";

                                        //mark as form incomplete
                                        form_incomplete = true;
                                    }
                                    else
                                    {
                                        //reset border back to default
                                        this_input.Style.Border = "";
                                        this_input.Style.BorderColor = "";
                                        this_input.Style.BorderStyle = "";
                                    }
                                }

                                string value = this_input.Value;
                                form_json = form_json + "\"" + field + "\":\"" + value + "\",";
                            }
                            
                            if (form_incomplete)
                            {
                                status.InnerHTML = "Please complete the required form elements!";
                                status_image.Src = "images/error_status.png";
                                submit_button.Disabled = false;
                                return;
                            }
                            
                            //form_json = form_json.TrimEnd(',');

                            //add current selected price
                            form_json = form_json + "\"selected_identifier\":\"" + selected_identifier.ToString() + "\"";

                            form_json_container.InnerHTML = form_json + "}";
                        }
                        else
                        {
                            //TODO: remove the comma?
                        }

                        try
                        {
                            //TODO:
                            //here is where the service/token/tos text on this card should be inserted
                            //the javascript funcion should accept them as arguments
                            //Console.WriteLine("submitting charge Disabled: " + submit_button.Disabled.ToString());

                            //Bridge.Script.Write("myFunction();");
                            Bridge.Script.Eval("SubmitCharge(\"" + AlertConfirmText + "\",\"" + service + "\",\"" + status.Id + "\",\"" + PostUrl + "\",\"" + ApiKeyVal + "\",\"" + submit_button.Id + "\");");

                            //TODO: maybe reset submit button to not disabled if there is an error?
                            //submit_button.Disabled = false;
                            //NAH NAH -> instead supply the submitcharge function the id of the button so it can set disabled to false
                            //Console.WriteLine("after submitting Disabled: " + submit_button.Disabled.ToString());
                        }
                        catch (Exception ex)
                        {
                            status.InnerHTML = ex.Message;
                            status_image.Src = "images/error_status.png";
                        }


                        //https://stripe.com/docs/disputes/prevention
                        //your terms of service and policies easy to find on your website, and require customers to agree to them
                        //There must be reasonable evidence that your customer was presented with a full copy of your policies prior to their purchase.

                        //TODO: ^^^^^
                        //https://www.w3schools.com/howto/howto_css_modals.asp
                        //or
                        //https://www.w3schools.com/js/js_popup.asp

                        //Does the billing address match the shipping address?
                        //Has the billing address been verified by AVS? Does it also match the card’s country of origin?
                        //Does the customer’s email address match the cardholder’s name?
                        //Is this an order that the customer has asked to be expedited?
                        //Have multiple orders from different credit cards originated from this same IP address?
                        //Has this customer made many order attempts that have been declined ?

                        //Bridge.Script.Write("var form_json_str = JSON.stringify(keyValuePairs);");

                        string b = "l";
                    };
                    
                    card_container.AppendChild(error_container);


                    //hidden status div
                    //status.Style.Visibility = Visibility.Hidden;

                    //container.AppendChild(card_label);
                    container.AppendChild(card_container);
                    //container.AppendChild(submit_button);
                    submission_container.AppendChild(submit_button);
                    submission_container.AppendChild(status_image);
                    submission_container.AppendChild(status);
                    //container.AppendChild(status);
                    //container.AppendChild(status_image);
                    container.AppendChild(submission_container);
                    container.AppendChild(form_json_container);
                    break;
            }
            Indent();
        }
    }
}
