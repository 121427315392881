using Bridge.Html5;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace StaticSiteToolbox.Controls
{
    public class BannerCard : Card
    {
        public string ImageSrcMobile { get; set; }
        public string ImageSrcDesktop { get; set; }
        public int MaxHeight { get; set; }
        public int MinWidth { get; set; }
        public int HeaderFontSize { get; set; }
        public string Header { get; set; }
        public string HeaderFontColor { get; set; }
        public string Font { get; set; }

        new public void Draw()
        {
            //banner
            var banner_wrapper = new HTMLDivElement
            {
                Style =
                {
                    //Color = BannerTextColor,
                    //FontFamily = App.FontMain,
                    TextAlign = TextAlign.Center,
                    Position = Position.Relative,
                    Overflow = Overflow.Hidden,
                    MaxHeight = MaxHeight.ToString() + "px",

                },
            };

            var bannerImg = new HTMLImageElement
            {
                Style =
                {
                    Width = "100%", //always as wide as window
                    MinWidth = MinWidth.ToString() + "px", //dont horizontally shrink below mobile
                    MinHeight = MaxHeight.ToString() + "px", //dont scale with window height
                },
            };

            var bannerText = new HTMLDivElement
            {
                InnerHTML = Header,
                Style =
                {
                    Color = HeaderFontColor,
                    FontSize = HeaderFontSize.ToString() + "px",
                    FontFamily = Font,
                    Top = "50%",
                    TextAlign = TextAlign.Left,
                    VerticalAlign = VerticalAlign.TextBottom,
                    Position = Position.Absolute,
                    //Transform = "translate(0%, -50%)"
                }
            };

            if (App.UseDesktop)
            {
                bannerImg.Src = ImageSrcDesktop;
                bannerText.Style.Left = App.site_params.general.MainMarginPercent + "%";
            }
            else
            {
                bannerImg.Src = ImageSrcMobile;
                bannerText.Style.Left = App.site_params.general.SecondaryMarginPercent + "%";
            }

            banner_wrapper.AppendChild(bannerImg);
            banner_wrapper.AppendChild(bannerText);
            container = banner_wrapper;

        }
    }
}
