using Bridge.Html5;
using StaticSiteToolbox.Models;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace StaticSiteToolbox.Controls
{
    public class FormCard : Card
    {
        public string Header { get; set; }
        public string Content { get; set; }
        public int HeaderFontSize { get; set; }
        public int ContentFontSize { get; set; }
        public string FontColor { get; set; }
        public string Font { get; set; }
        public string BackgroundColor { get; set; }

        public List<string> fields { get; set; } //list of text prompt per input to capture
        public List<bool> required { get; set; } //list of whether input is required for submission
        public List<List<string>> selections { get; set; } //list of possible items to select per input
        public List<Bridge.Html5.InputType> inputTypes { get; set; }

        public bool IncludeSubmit { get; set; }

        public string BtnBackgroundColor { get; set; } //background color
        public string BtnTextColor { get; set; } //text in button color
        public string BtnTextHighlightColor { get; set; } //color when button mouseover
        public string BtnText { get; set; } //text in submit button

        public string PostUrl { get; set; }
        public string ApiKeyVal { get; set; }

        private bool CollectUserDataOnSubmit = true;
        private bool CollectGeolocation = false;

        public string OnCompletePage { get; set; }

        new public void Draw()
        {
            var header = new HTMLDivElement
            {
                InnerHTML = Header,
                Style =
                {
                    FontSize = HeaderFontSize.ToString() + "px",
                    Color = FontColor,
                    TextAlign = TextAlign.Left,
                    Padding = "10px",
                    FontFamily = Font
                }
            };

            var content = new HTMLDivElement
            {
                InnerHTML = Content,
                Style =
                {
                    FontSize = ContentFontSize.ToString() + "px",
                    Color = FontColor,
                    TextAlign = TextAlign.Left,
                    Padding = "10px",
                    FontFamily = Font
                }
            };

            //
            container = new HTMLDivElement
            {
                Style =
                {
                    Display = Display.Block,
                    Height = "100%",
                    BackgroundColor = BackgroundColor
                }
            };

            container.AppendChild(header);
            container.AppendChild(content);

            //
            for (int i = 0; i < fields.Count; i++)
            {
                var input_line = new HTMLDivElement
                {
                    InnerHTML = fields[i],
                    Style =
                    {
                        Display = Display.Grid,
                        Height = "100%",
                        BackgroundColor = BackgroundColor,
                        FontFamily = Font,
                        //Width = "80px"
                        Width = "60%",
                        //Resize = Resize.Both,
                        //Overflow = Overflow.Auto,
                        MaxWidth = "300px",
                    }
                };
                //Console.WriteLine(selections.Count.ToString());
                if (selections.Count > 0) //if there are selections
                {
                    if (selections[i].Count > 0)
                    {
                        string name = fields[i];
                        foreach (string opt in selections[i])
                        {
                            var selection_line = new HTMLDivElement
                            {
                                Style =
                                {
                                    Display = Display.Flex,
                                    //Height = "100%",
                                    //VerticalAlign = VerticalAlign.Bottom,
                                    BackgroundColor = BackgroundColor,
                                    FontFamily = Font,
                                    //Width = "80px"
                                    //Width = "30%"
                                    //Resize = Resize.Both,
                                }
                            };
                            var input = new HTMLInputElement
                            {
                                Type = inputTypes[i],
                                Required = required[i],
                                Id = fields[i] + "_" + opt,
                                Value = opt,
                                Name = name,
                                Style =
                                {
                                    FontSize = ContentFontSize.ToString() + "px",
                                    Color = FontColor,
                                    //TextAlign = TextAlign.Left,
                                    Padding = "10px",
                                    Margin = "20px",
                                    FontFamily = Font,
                                    //Display = Display.Block,
                                    //MinWidth = "25px",
                                    //MinHeight = "15px"
                                    //Resize = Resize.Both,
                                }
                            };
                            var selection = new HTMLDivElement
                            {
                                InnerHTML = opt,
                                Style =
                                {
                                    //Display = Display.Block,
                                    //TextAlign = TextAlign.Center,
                                    //VerticalAlign = VerticalAlign.Baseline,
                                    //Height = "100%",
                                    Margin = "20px",
                                    BackgroundColor = BackgroundColor,
                                    FontFamily = Font,
                                    //Width = "80px"
                                    //Width = "30%"
                                    //Resize = Resize.Both,
                                }
                            };

                            selection_line.OnClick = (ev) =>
                            {
                                input.Checked = true;
                            };

                            selection_line.AppendChild(input);
                            selection_line.AppendChild(selection);
                            input_line.AppendChild(selection_line);
                        }
                    }
                    else
                    {
                        if (inputTypes[i] == (InputType)23)
                        {
                            var input = new HTMLTextAreaElement
                            {
                                //Type = inputTypes[i],
                                Required = required[i],
                                Id = fields[i],
                                Style =
                                {
                                    FontSize = ContentFontSize.ToString() + "px",
                                    Color = FontColor,
                                    TextAlign = TextAlign.Left,
                                    Padding = "10px",
                                    Margin = "20px",
                                    FontFamily = Font,
                                    MinWidth = "25px",
                                    MinHeight = "15px",
                                    Width = "100%",
                                }
                            };
                            input_line.AppendChild(input);
                            input_line.Style.Width = "75%";
                        }
                        else
                        {
                            var input = new HTMLInputElement
                            {
                                Type = inputTypes[i],
                                Required = required[i],
                                Id = fields[i],
                                Style =
                                {
                                    FontSize = ContentFontSize.ToString() + "px",
                                    Color = FontColor,
                                    TextAlign = TextAlign.Left,
                                    Padding = "10px",
                                    Margin = "20px",
                                    FontFamily = Font,
                                    MinWidth = "25px",
                                    MinHeight = "15px",
                                }
                            };
                            input_line.AppendChild(input);
                        }
                    }
                }
                else
                {
                    if (inputTypes[i] == (InputType)23)
                    {
                        var input = new HTMLTextAreaElement
                        {
                            //Type = inputTypes[i],
                            Required = required[i],
                            Id = fields[i],
                            Style =
                            {
                                FontSize = ContentFontSize.ToString() + "px",
                                Color = FontColor,
                                TextAlign = TextAlign.Left,
                                Padding = "10px",
                                Margin = "20px",
                                FontFamily = Font,
                                MinWidth = "25px",
                                MinHeight = "15px",
                                Width = "100%",
                            }
                        };
                        input_line.AppendChild(input);
                        input_line.Style.Width = "75%";
                    }
                    else
                    {
                        var input = new HTMLInputElement
                        {
                            Type = inputTypes[i],
                            Required = required[i],
                            Id = fields[i],
                            Style =
                            {
                                FontSize = ContentFontSize.ToString() + "px",
                                Color = FontColor,
                                TextAlign = TextAlign.Left,
                                Padding = "10px",
                                Margin = "20px",
                                FontFamily = Font,
                                MinWidth = "25px",
                                MinHeight = "15px",
                            }
                        };
                        input_line.AppendChild(input);
                    }
                }
                container.AppendChild(input_line);

            }

            if (IncludeSubmit)
            {
                var status = new HTMLDivElement
                {
                    Id = "status_div",
                    InnerHTML = "",
                    Style =
                    {
                        FontSize = "20".ToString() + "px",
                        Color = App.site_params.general.ColorFontSecondary,
                        TextAlign = TextAlign.Left,
                        Padding = "10px",
                        FontFamily = App.site_params.general.FontMain,
                        Width = "50%",
                        Overflow = Overflow.Visible,
                    }
                };

                
                var submit_button = new HTMLButtonElement
                {
                    Id = "submit_button",
                    InnerHTML = "<strong>" + BtnText + "</strong>",
                    Style =
                        {
                            Padding = "20px",
                            Color = BtnTextColor,
                            BackgroundColor = BtnBackgroundColor,
                            Border = "0px", //no border
                            FontSize = ContentFontSize.ToString() + "px",
                            Flex = "0 0 auto",
                            FontFamily = Font,
                            BorderRadius = "5px",
                            Cursor = "pointer",
                        }
                };

                //event to call to update the status items
                status.OnChange = (ev) =>
                {
                    
                    //have to try because innerhtml may not have double quotes so indexing fails
                    if (status.InnerHTML.Contains("\""))
                    {
                        string raw_str_resp = status.InnerHTML.Split('"')[1];
                        //Console.WriteLine(raw_str_resp);
                        //Console.WriteLine(OnCompletePage);
                        if (raw_str_resp == OnCompletePage)
                        {
                            //assume success?
                            //status_image.Src = "images/success_status.png";

                            //navigate to the completed page
                            Navigation.ChangeContentView(OnCompletePage);
                        }
                        else
                        {
                            //Console.WriteLine("1changing submit btn disabled to false");
                            submit_button.Disabled = false;
                        }
                    }
                    else
                    {
                        //Console.WriteLine("2changing submit btn disabled to false");
                        submit_button.Disabled = false;
                    }
                };

                submit_button.OnMouseOver = (ev) =>
                {
                    submit_button.Style.Color = BtnTextHighlightColor;
                };
                submit_button.OnMouseLeave = (ev) =>
                {
                    submit_button.Style.Color = BtnTextColor;
                };

                submit_button.OnClick = (ev) =>
                {
                    //Console.WriteLine("on clicking Disabled: " + submit_button.Disabled.ToString());
                    //set the submit button to disabled so cant be presssed again until response
                    submit_button.Disabled = true;
                    //status_image.Src = "images/loading_status.png";
                    //Console.WriteLine("-> Disabled: " + submit_button.Disabled.ToString());

                    //reset the form container and form json string
                    string form_json = "{"; //",";
                    status.InnerHTML = "Submitting...";

                    //collect the user browser data, if specified
                    List<Tuple<string, string>> user_data = new List<Tuple<string, string>>();
                    if (CollectUserDataOnSubmit)
                    {
                        if (App.my_position == null && CollectGeolocation)
                        {
                            //TODO: make a pop up instead
                            status.InnerHTML = "You must allow your location to be shared in order to complete this purchase.";
                            user_data = App.CollectUserData(CollectGeolocation);
                            submit_button.Disabled = false;
                            return;
                        }

                        user_data = App.CollectUserData(CollectGeolocation);
                        foreach (Tuple<string, string> pair in user_data)
                        {
                            form_json = form_json + "\"" + pair.Item1 + "\":\"" + pair.Item2 + "\",";
                        }
                    }

                    //collect information from form card
                    if (fields.Count > 0)
                    {
                        bool form_incomplete = false;

                        for (int i = 0; i < fields.Count; i++)
                        {
                            string field = fields[i];
                            string value = "";

                            //if this field input is a text area
                            if (inputTypes[i] == (InputType)23)
                            {
                                var this_input = Document.GetElementById<HTMLTextAreaElement>(fields[i]);

                                if (this_input.Required)
                                {
                                    //check if filled out 
                                    if (!this_input.Validity.Valid)
                                    {
                                        //if not color its border red
                                        this_input.Style.Border = "2px";
                                        this_input.Style.BorderColor = "red";
                                        this_input.Style.BorderStyle = "solid";

                                        //mark as form incomplete
                                        form_incomplete = true;
                                    }
                                    else
                                    {
                                        //reset border back to default
                                        this_input.Style.Border = "";
                                        this_input.Style.BorderColor = "";
                                        this_input.Style.BorderStyle = "";
                                    }
                                }

                                value = this_input.Value;

                            }
                            else if (inputTypes[i] == InputType.Radio) //field has a radio selection
                            {
                                try
                                {

                                    //go through each selection and if checked, use that value
                                    foreach (string opt in selections[i])
                                    {
                                        var this_input = Document.GetElementById<HTMLInputElement>(fields[i] + "_" + opt);
                                        if (this_input.Checked)
                                        {
                                            value = this_input.Value;
                                        }
                                    }

                                    if (required[i])
                                    {
                                        //check if filled out 
                                        if (value == "")
                                        {
                                            foreach (string opt in selections[i])
                                            {
                                                var this_input = Document.GetElementById<HTMLInputElement>(fields[i] + "_" + opt);
                                                var selection_line = this_input.ParentElement;
                                                //if not color its border red
                                                selection_line.Style.Border = "2px";
                                                selection_line.Style.BorderColor = "red";
                                                selection_line.Style.BorderStyle = "solid";
                                            }
                                            //mark as form incomplete
                                            form_incomplete = true;
                                        }
                                        else
                                        {
                                            foreach (string opt in selections[i])
                                            {
                                                var this_input = Document.GetElementById<HTMLInputElement>(fields[i] + "_" + opt);
                                                var selection_line = this_input.ParentElement;
                                                //reset border back to default
                                                selection_line.Style.Border = "";
                                                selection_line.Style.BorderColor = "";
                                                selection_line.Style.BorderStyle = "";
                                            }
                                        }
                                    }
                                }
                                catch (Exception ex)
                                {
                                    Console.WriteLine(ex.Message);
                                }
                            }
                            else //password, or email, or text
                            {
                                var this_input = Document.GetElementById<HTMLInputElement>(fields[i]);

                                if (this_input.Required)
                                {
                                    //check if filled out 
                                    if (!this_input.Validity.Valid)
                                    {
                                        //if not color its border red
                                        this_input.Style.Border = "2px";
                                        this_input.Style.BorderColor = "red";
                                        this_input.Style.BorderStyle = "solid";

                                        //mark as form incomplete
                                        form_incomplete = true;
                                    }
                                    else
                                    {
                                        //reset border back to default
                                        this_input.Style.Border = "";
                                        this_input.Style.BorderColor = "";
                                        this_input.Style.BorderStyle = "";
                                    }
                                }

                                value = this_input.Value;
                            }

                            form_json = form_json + "\"" + field + "\":\"" + value + "\",";

                        }

                        if (form_incomplete)
                        {
                            status.InnerHTML = "Please complete the required form elements!";
                            //status_image.Src = "images/error_status.png";
                            submit_button.Disabled = false;
                            return;
                        }

                        form_json = form_json.TrimEnd(',');
                        form_json += "}";


                        //Console.WriteLine(form_json);

                    }
                    else
                    {
                        //TODO: remove the comma?
                    }


                    
                    try
                    {
                        //TODO:
                        //here is where the service/token/tos text on this card should be inserted
                        //the javascript funcion should accept them as arguments
                        //Console.WriteLine("submitting charge Disabled: " + submit_button.Disabled.ToString());

                        //Bridge.Script.Write("myFunction();");
                        //Bridge.Script.Eval("SubmitCharge(\"\",\"" + service + "\",\"" + status.Id + "\",\"" + PostUrl + "\",\"" + ApiKeyVal + "\",\"" + submit_button.Id + "\");");
                        //string javascript_send = "SendData(\"POST\",\"" + PostUrl + "\",\"" + form_json + "\",\"" + ApiKeyVal + "\",\"status_div\",\"Great Success!\");";
                        //Console.WriteLine(javascript_send);
                        //Bridge.Script.Eval(javascript_send);

                        //TODO: maybe reset submit button to not disabled if there is an error?
                        //submit_button.Disabled = false;
                        //NAH NAH -> instead supply the submitcharge function the id of the button so it can set disabled to false
                        //Console.WriteLine("after submitting Disabled: " + submit_button.Disabled.ToString());

                        string javascript_send = "SendData('POST','" + PostUrl + "','" + form_json + "','" + ApiKeyVal + "','status_div','Great Success!');";
                        Bridge.Script.Eval(javascript_send);
                    }
                    catch (Exception ex)
                    {
                        status.InnerHTML = "Oops! Something went wrong with this submission form.";//ex.Message;
                        //status_image.Src = "images/error_status.png";
                    }

                };


                container.AppendChild(submit_button);
                container.AppendChild(status);
            }

            Indent();
        }
    }
}
