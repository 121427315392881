using Bridge.Html5;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace StaticSiteToolbox.Controls
{
    public class Card
    {
        public HTMLDivElement container { get; set; }

        private string top_padding_desktop = "40";
        private string bot_padding_desktop = "60";
        private string top_padding_mobile = "20";
        private string bot_padding_mobile = "30";

        public void Indent()
        {
            if (App.UseDesktop)
            {
                container.Style.PaddingTop = top_padding_desktop + "px";
                container.Style.PaddingBottom = bot_padding_desktop + "px";
                //container.Style.PaddingLeft = App.site_params.general.MainMarginPercent.ToString() + "%"; //TODO: change this to logowidth + padding (for all pages?)
                //container.Style.PaddingRight = (App.site_params.general.MainMarginPercent + App.site_params.general.BackupMarginPercent).ToString() + "%";
                //container.Style.Width = (100 - App.site_params.general.BackupMarginPercent).ToString() + "%";
            }
            else
            {
                container.Style.PaddingTop = top_padding_mobile + "px";
                container.Style.PaddingBottom = bot_padding_mobile + "px";

                //container.Style.PaddingLeft = App.site_params.general.SecondaryMarginPercent.ToString() + "%";
                //container.Style.PaddingRight = App.site_params.general.MainMarginPercent.ToString() + "%";
                
                //container.Style.Width = "100%"; //this makes the text expand all the way
            }
            container.Style.PaddingLeft = App.site_params.general.MainMarginPercent.ToString() + "%"; //TODO: change this to logowidth + padding (for all pages?)
            container.Style.PaddingRight = (App.site_params.general.MainMarginPercent + App.site_params.general.BackupMarginPercent).ToString() + "%";
            container.Style.Width = (100 - App.site_params.general.BackupMarginPercent).ToString() + "%";

        }

        public void Draw()
        {
            container = new HTMLDivElement
            {
                //set inner html to blank text -> assume controls that inherit will override this draw
                InnerHTML = "Blank Card"
            };
        }
    }
}
