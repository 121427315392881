using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using Bridge.Html5;
using StaticSiteToolbox.Controls;
using StaticSiteToolbox.ViewModels;

namespace StaticSiteToolbox.Views
{
    public class MainView
    {
        //containing div elements for different devices
        //public HTMLDivElement Desktop { get; set; }
        //public HTMLDivElement Mobile { get; set; }
        public HTMLDivElement Content { get; set; }

        //content viewer that top navigation can redraw
        public ContentViewer contentViewer { get; set; }
        public NavigationBar navigationBar { get; set; }

        private HTMLDivElement main_wrapper { get; set; }

        public MainView()
        {
            //this is the main view construction for the entire website
            Content = new HTMLDivElement
            {
                Style =
                {
                    //Clear = Clear.Both,
                    //Height = "100%",
                    //Overflow = Overflow.Scroll
                },
            };

            contentViewer = new ContentViewer();
        }

        public void Draw()
        {
            //create top navigation bar
            if (App.site_params.general.UseNavigationBar)
            {
                //grab the navigation bar from the site params
                navigationBar = App.site_params.navbar;
                
                //draw the navigation bar
                navigationBar.Draw();

                //draw content view
                contentViewer.Draw();

                //Navigation.CurrentPage = contentViewer.Content;

                if (App.site_params.general.NavAboveContent)
                {
                    Content.AppendChild(contentViewer.Content);
                    Content.AppendChild(navigationBar.Content);
                }
                else
                {
                    Content.AppendChild(navigationBar.Content);
                    Content.AppendChild(contentViewer.Content);
                }

            }
            else
            {
                //not using navigation bar
            }
        }
    }
}
