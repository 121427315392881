using Bridge.Html5;
using StaticSiteToolbox.Models;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace StaticSiteToolbox.Controls
{

    public class LinkCard : Card
    {
        public string Header { get; set; }
        public string Content { get; set; }
        public int HeaderFontSize { get; set; }
        public int ContentFontSize { get; set; }
        public string FontColor { get; set; }
        public string Font { get; set; }
        public string BackgroundColor { get; set; }

        public List<string> PageNames { get; set; }
        public List<string> Aliases { get; set; }
        public string LinkTextColor { get; set; }
        public string HoverTextColor { get; set; }

        public string prepend = "";

        new public void Draw()
        {
            var header = new HTMLDivElement
            {
                InnerHTML = Header,
                Style =
                {
                    FontSize = HeaderFontSize.ToString() + "px",
                    Color = FontColor,
                    TextAlign = TextAlign.Left,
                    Padding = "10px",
                    FontFamily = Font,
                }
            };

            var content = new HTMLDivElement
            {
                InnerHTML = Content,
                Style =
                {
                    FontSize = ContentFontSize.ToString() + "px",
                    Color = FontColor,
                    TextAlign = TextAlign.Left,
                    Padding = "10px",
                    FontFamily = Font,
                }
            };

            //create list to contain navigation buttons
            var nav_list = new HTMLUListElement
            {
                Style =
                {
                    Display = Display.Table,
                    AlignItems = AlignItems.Stretch,
                    //JustifyContent = JustifyContent.SpaceBetween,
                    //AlignContent = AlignContent.FlexEnd,
                    //VerticalAlign = VerticalAlign.TextTop,

                    //Position = Position.Absolute,
                    //PaddingRight = App.BackupMarginPercent.ToString() + "px",
                    //Top = "0px"
                },
            };

            //make nav buttons

            //TODO:
            //make so if only pagename, then make a call to action button
            if (PageNames.Count == 1)
            {
                //only add a CTA button
                var nav_button = new HTMLButtonElement
                {
                    Id = prepend + PageNames[0], //why prepend?
                    InnerHTML = "<b>" + Aliases[0] + "</b>", //bolden the alias
                    Style =
                    {
                        Padding = "20px",
                        Color = BackgroundColor,
                        BackgroundColor = HoverTextColor,//set button background to hover color
                        Border = "0px", //no border
                        FontSize = ContentFontSize.ToString() + "px",
                        Flex = "0 0 auto",
                        FontFamily = Font,
                        BorderRadius = "5px",
                        Cursor = "pointer",
                    },

                };
                
                //add on hover event to change text color
                nav_button.OnMouseOver = (ev) =>
                {
                    nav_button.Style.Color = LinkTextColor;
                };
                nav_button.OnMouseLeave = (ev) =>
                {
                    if (nav_button.Id != Navigation.CurrentPageName)
                    {
                        nav_button.Style.Color = BackgroundColor;
                    }

                };

                //add on click text color and current selection change
                nav_button.OnClick = (ev) =>
                {
                    nav_button.Style.Color = HoverTextColor;

                    foreach (HTMLButtonElement button in nav_list.Children)
                    {
                        if (button.Id == Navigation.CurrentPageName)
                        {
                            button.Style.Color = LinkTextColor;
                        }
                    }

                    //change the content view to the new page clicked
                    Navigation.ChangeContentView(nav_button.Id);
                };

                nav_list.AppendChild(nav_button);
            }
            else
            {

                for (int i = 0; i < PageNames.Count; i++)
                //foreach (string item in PageNames)
                {
                    //create navigation button
                    var nav_button = new HTMLButtonElement
                    {
                        Id = prepend + PageNames[i], //why prepend?
                        InnerHTML = "<b>" + Aliases[i] + "</b>", //bolden the alias
                        Style =
                        {
                            //Display = Display.InlineFlex,
                            Padding = "20px",
                            Color = LinkTextColor,
                            BackgroundColor = "rgba(0,0,0,0)", //transparent
                            Border = "0px", //no border
                            FontSize = ContentFontSize.ToString() + "px",
                            Flex = "0 0 auto",
                            FontFamily = Font,
                            Cursor = "pointer",
                        },

                    };

                    //set text color of current selection
                    if (nav_button.Id == Navigation.CurrentPageName)
                    {
                        nav_button.Style.Color = HoverTextColor;
                    }

                    //add on hover event to change text color
                    nav_button.OnMouseOver = (ev) =>
                    {
                        nav_button.Style.Color = HoverTextColor;
                    };
                    nav_button.OnMouseLeave = (ev) =>
                    {
                        if (nav_button.Id != Navigation.CurrentPageName)
                        {
                            nav_button.Style.Color = LinkTextColor;
                        }

                    };

                    //add on click text color and current selection change
                    nav_button.OnClick = (ev) =>
                    {
                        nav_button.Style.Color = HoverTextColor;

                        foreach (HTMLButtonElement button in nav_list.Children)
                        {
                            if (button.Id == Navigation.CurrentPageName)
                            {
                                button.Style.Color = LinkTextColor;
                            }
                        }

                        //change the content view to the new page clicked
                        Navigation.ChangeContentView(nav_button.Id);
                    };

                    nav_list.AppendChild(nav_button);
                }
            }

            container = new HTMLDivElement
            {
                Style =
                {
                    Display = Display.Table,
                    BackgroundColor = BackgroundColor
                }
            };

            container.AppendChild(header);
            container.AppendChild(content);
            container.AppendChild(nav_list);

            Indent();
        }
    }
}
