using Bridge.Html5;
using StaticSiteToolbox.Controls;
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;

namespace StaticSiteToolbox.ViewModels
{
    public class ContentViewer
    {
        //public HTMLDivElement Desktop { get; set; }
        //public HTMLDivElement Mobile { get; set; }
        public HTMLDivElement Content { get; set; }

        private List<Page> pages = new List<Page>();

        public void Draw()
        {
            //why have this? not just DrawContents?
            if (App.UseDesktop)
            {
                DrawContent();
            }
            else
            {
                DrawContent();
            }
        }

        private void DrawContent()
        {
            //PageBuilder will go here
            //so no switching
            //page = PageBuilder.Construct()
            //Content = page.Content

            Page page = PageBuilder.Construct(Window.Location.Hash.Split('#')[1]);
            //page.Draw();
            Content = page.Content;
            pages.Add(page);
            
            Content.Style.Position = Position.Absolute;
            Content.Style.Left = "0px";
            Content.Style.Overflow = Overflow.Auto;
            //ResizeVertical();
            //Window.Location.Hash = Navigation.CurrentPageName;

        }



        public void ResizeVertical()
        {
            //need to resize the scroller of each page
            foreach (Page page in pages)
            {
                page.scrollPositioner.RePosition();
                page.ResizeContent();
            }
        }
    }
}
